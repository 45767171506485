import { Grid, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Search = ({ setSearchKeyword }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setSearchKeyword(event.target.value.trim());
  };

  const handleClose = () => {
    setSearchValue("");
    setSearchKeyword("");
  };

  return (
    <Grid
      container
      justifyContent={isMobile ? "flex-start" : "center"}
      alignItems="center">
      <TextField
        variant="outlined"
        placeholder="Search"
        type="text"
        value={searchValue}
        onChange={handleSearch}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#666", fontSize: isMobile ? "18px" : "22px" }} />
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClose}
                size="small">
                <ClearIcon style={{ fontSize: isMobile ? "16px" : "18px" }} />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: "25px",
            backgroundColor: "#f5f5f5",
            height: isMobile ? "38px" : isTablet ? "44px" : "50px",
            paddingLeft: "12px",
            fontSize: isMobile ? "13px" : "15px",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
            backgroundColor: "#f5f5f5",
            height: "30px",
            paddingLeft: "10px",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "#ebebeb",
            },
            "&.Mui-focused": {
              backgroundColor: "#fff",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)", // Highlight effect
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "4px 12px",
            fontSize: isMobile ? "13px" : "15px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          width: isMobile ? "90%" : isTablet ? "60%" : "300px",
          maxWidth: "100%",
        }}
      />
    </Grid>
  );
};

Search.propTypes = {
  setSearchKeyword: PropTypes.func,
};

export default Search;
