import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RoutesList from "../config/Routes";
import SideBar from "./shared/sidebar/SideBar";
import { getAccessToken } from "./authentication/HandleCookies";

const Landingpage = ({ setPageError }) => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = RoutesList.find(route => route.path === location.pathname);
    document.title = currentRoute?.title || "HRMS-Portal";
  }, [location.pathname]);

  let newpath = location.pathname;
  let pathIncID;
  if (newpath.split("/").length === 3) {
    pathIncID = newpath.split("/")[1].includes("");
  } else if (newpath.split("/").length === 4) {
    pathIncID = newpath.split("/")[3].includes("");
  }

  const routeExist = RoutesList.find((item) => {
    if (!pathIncID) {
      return newpath === item.path;
    } else {
      return newpath.split("/")[1] === item.path.split("/")[1];
    }
  });

  if (!routeExist) {
    setPageError(true);
  }

  let token = getAccessToken();
  if (!token || token.length <= 0) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <SideBar>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/dashboard" />}
          />

          {RoutesList.map((route, i) => {
            return (
              <Route
                key={`route-${i}`}
                path={route.path}
                element={<route.component />}
              />
            );
          })}
        </Routes>
      </SideBar>
    </>
  );
};
Landingpage.propTypes = {
  setPageError: PropTypes.func.isRequired,
};
export default Landingpage;
