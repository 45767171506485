import React from "react";
import AppConstant from "../../../constants/AppConstant";
export default function Copyright() {
  return (
    <>
      <div className="text-center">
        <div>A product of</div> ©&nbsp;{AppConstant.copyRightText} <div>Pvt. Ltd. {new Date().getFullYear()}</div>
      </div>
    </>
  );
}
