import { useState } from "react";
import { Col, Row } from "reactstrap";
import { downloadAssetFile } from "../../redux/ApiLists";
import ProfilePic from "../profilePic/ProfilePic";
import { assetStatus } from "../shared/Enums";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import { getProfile } from "../authentication/HandleCookies";
import CompactMenuWithActions from ".././shared/spinner/CompactMenuWithActions";
import { getActionsObject } from "../../util/Helper";
import { deleteDocumentApi } from "../../redux/ApiLists";
import { DOCUMENT_TYPES } from "../shared/Enums";

const AssetsList = ({filterDoc = [], entityId, documentType, listType, disable, disableAddAndDeleteButton}) => {
  const cubeModelName = "Asset";
  const [reload] = useState(false);
  const [, setSelectedRowData] = useState([]);
  const [selectedRowId] = useState();

  const URLS = {
    download: `${downloadAssetFile}`,
  };
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.assetCode`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.assetId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "ID",
      value: `Employee.employeeId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "asset.cols.txt.imagePath",
      value: `image_cols`,
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isCustomCol: true,
    },
    {
      text: "asset.cols.txt.assetName",
      value: `${cubeModelName}.fullName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "assetCode",
      value: `${cubeModelName}.assetCode`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "assetName",
      value: `${cubeModelName}.assetName`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "asset.cols.txt.assetType",
      value: `${cubeModelName}.assetType`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "asset.cols.txt.dateOfPurchase",
      value: `${cubeModelName}.dateOfPurchase`,
      type: "date",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
    },
    {
      text: "asset.cols.txt.modelNumber",
      value: `${cubeModelName}.modelNumber`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
    },
    {
      text: "asset.cols.txt.serialNumber",
      value: `${cubeModelName}.serialNumber`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
    },
    {
      text: "asset.cols.txt.location",
      value: `${cubeModelName}.location`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      defaultVal: "--",
    },

    {
      text: "asset.cols.txt.status",
      value: `${cubeModelName}.status`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isStatusColumn: true,
      statusTypeList: assetStatus,
      isSearchable: true,
    },
    {
      text: "asset.cols.txt.assignDate",
      value: `${cubeModelName}.assignDate`,
      type: "date",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
    },
      {
         text: "cols.txt.action",
         value: "action_column",
         isDisplay: true,
         isVisible: false,
         isCustomCol: true,
         isSorting: false,
         style: { position: "sticky", right: "0px", width: "0px", backgroundColor: "#fefefe" },
       },
     ];
   
     const API = {
       delete: `${deleteDocumentApi}`,
     };
   
  const refreshTable = () =>
    setTimeout(() => {
      reloadTable((prev) => !prev);
    }, 1500);
  const filterOptions = [];
  //Custom column UI function
  const loadImage = (rowData) => {
    return (
      <Row>
        <Col>
          <div style={{ width: "4rem" }}>
            {
              <ProfilePic
                Id={rowData[`Asset.assetId`]}
                shouldEdit={false}
                assetDefaultImg={true}
                urls={URLS}
              />
            }
          </div>
        </Col>
      </Row>
    );
  };

   const actionHtml = (rowData) => {
      const options = getActionsObject(rowData, cubeModelName);
      return (
        <Row>
          <Col className="actionButtons">
            <CompactMenuWithActions
              options={options}
              rowData={rowData}
              reloadTable={refreshTable}
              cubeModelName={cubeModelName}
              showAttachments={true}
              showEditComponent={false}
              ShowStatus={false}
              api={API}
              Id={rowData[`${cubeModelName}.assetId`]}
              message={"Employee Document"}
              cubeModelId={rowData[`${cubeModelName}.assetId`]}
              documentType={DOCUMENT_TYPES.ASSET_IMAGE}
              disableButton={rowData[`${cubeModelName}.documentStatus`] == "Approved" ? true : false}
              disableAddAndDeleteButton={rowData[`${cubeModelName}.documentStatus`] == "Approved" ? true : false}
              assestFile={true}
            />
          </Col>
        </Row>
      );
    };
  
  const customCols = [{ id: "image_cols", component: loadImage },
    {id: "action_column", component: actionHtml }
  ];
  const customToolList = [];
  return (
    <>
      <TitleHeader pageTitle={"title.assets"} />
      <DataTableWrapper
        key={`key_asset_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.assetsList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        selectedRowId={selectedRowId}
        filterOptions={filterOptions}
      />
    </>
  );
};
export default AssetsList;
