import Cookies from "js-cookie";

const clearCookies = async () => {
  const mainDomain = process.env.REACT_APP_COOKIES_MAIN_DOMAIN;
  const alternateDomain = process.env.REACT_APP_COOKIES_ALTERNATE_DOMAIN;

  // Determine domain based on the current browser URL
  const currentHostname = window.location.hostname;
  const targetDomain = currentHostname.includes(mainDomain) ? mainDomain : alternateDomain;
  Cookies.remove("_acct_kn_em", {
    path: "/",
    domain: targetDomain,
  });
  Cookies.remove("profile_em", {
    path: "/",
    domain: targetDomain,
  });
  Cookies.remove("company_code_em", {
    path: "/",
    domain: targetDomain,
  });
};

const getAccessToken = () => {
  return Cookies.get("_acct_kn_em");
};
const getProfile = () => {
  return Cookies.get("profile_em") ? Cookies.get("profile_em") : null;
};
const getCompanyCode = () => {
  return Cookies.get("company_code_em");
};

const setCookies = (loginResponse) => {
  const mainDomain = process.env.REACT_APP_COOKIES_MAIN_DOMAIN;
  const alternateDomain = process.env.REACT_APP_COOKIES_ALTERNATE_DOMAIN;

  // Determine domain based on the current browser URL
  const currentHostname = window.location.hostname;
  const targetDomain = currentHostname.includes(mainDomain) ? mainDomain : alternateDomain;

  Cookies.set("_acct_kn_em", loginResponse.token.access_token, {
    path: "/",
    domain: targetDomain,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
  Cookies.set("profile_em", JSON.stringify(loginResponse.profile), {
    path: "/",
    domain: targetDomain,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
  Cookies.set("company_code_em", loginResponse.profile.attributes.company_code, {
    path: "/",
    domain: targetDomain,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
};

const convertSecondsToDay = (seconds) => {
  return Math.floor(seconds / (3600 * 24));
};

export { clearCookies, getAccessToken, getCompanyCode, getProfile, setCookies };
