import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  root: {},
  row: {},
  spacer: {
    flexGrow: 1,
  },
}));

const Toolbar = (props) => {
  Toolbar.propTypes = {
    className: PropTypes.string,
  };
  const { className, toolList, setSearchKeyword, toolbarSize, ...rest } = props;

  const classes = useStyles();
  return (
    <div {...rest}>
      <Grid
        container
        justifyContent="flex-end"
        spacing={1}
        className="mb-2">
        {toolList.map((tool) => tool)}
      </Grid>
    </div>
  );
};

export default Toolbar;
