import React from "react";
import { IntlProvider } from "react-intl";
import "./App.css";
import Maintenance from "./Maintenance";
import WrapperComponent from "./components/WrapperComponent";
import CubeJsApiWrapper from "./cube-config/cubeJsApiServer";
import AppLocale from "./lang";

function App() {
  const currentAppLocale = AppLocale["en"];
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;
  if (isUnderMaintenance == "true") {
    return <Maintenance />;
  }

  return (
    <>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <CubeJsApiWrapper>
          <WrapperComponent />
        </CubeJsApiWrapper>
      </IntlProvider>
    </>
  );
}

export default App;
