import { CubeContext } from "@cubejs-client/react";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import { educationDocDownload, employeeEducation } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import RctSectionLoader from "../shared/spinner/RctSectionLoader";
import AddEducationInfo from "./AddEducationInfo";
import { getActionsObject } from "../../util/Helper";
import { DOCUMENT_TYPES } from "../shared/Enums";
import CompactMenuWithActions from "../shared/spinner/CompactMenuWithActions";
import { getProfile } from "../authentication/HandleCookies";

const EducationInfo = () => {
  const { cubejsApi } = useContext(CubeContext);
  const cubeModelName = "EmployeeEducation";
  const [educationDetails, setEducationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowData, setRowData] = useState();
  const [reload, setReload] = useState(true);
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const EDUCATION_DETAIL_QUERY = {
    dimensions: [
      `${cubeModelName}.employeeEducationId`,
      `${cubeModelName}.employeeId`,
      `${cubeModelName}.courseStartDate`,
      `${cubeModelName}.courseEndDate`,
      `${cubeModelName}.courseType`,
      `${cubeModelName}.collegeName`,
      `${cubeModelName}.universityName`,
      `${cubeModelName}.qualificationType`,
      `${cubeModelName}.courseName`,
      `${cubeModelName}.stream`,
      `${cubeModelName}.grade`,
      `${cubeModelName}.description`,
      `${cubeModelName}.visibleToEmployee`,
      `${cubeModelName}.approved`,
    ],

    filters: [
      {
        member: `${cubeModelName}.employeeId`,
        operator: "equals",
        values: [`${employeeID}`],
      },
      {
        dimension: `${cubeModelName}.visibleToEmployee`,
        operator: "equals",
        values: ["true"],
      },
    ],
    order: {
      [`${cubeModelName}.courseEndDate`]: "desc",
    },
  };

  const getEmployeeEducationDetails = async () => {
    setIsLoading(true);
    try {
      const resultSet = await cubejsApi.load(EDUCATION_DETAIL_QUERY);
      const response = resultSet.tablePivot();
      setEducationDetails(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeEducationDetails();
  }, [reload]);

  function getEducationDetailObj(education) {
    let obj = {
      qualificationType: education["EmployeeEducation.qualificationType"],
      courseType: education["EmployeeEducation.courseType"],
      courseName: education["EmployeeEducation.courseName"],
      stream: education["EmployeeEducation.stream"],
      courseStartDate: education["EmployeeEducation.courseStartDate"],
      courseEndDate: education["EmployeeEducation.courseEndDate"],
      collegeName: education["EmployeeEducation.collegeName"],
      grade: education["EmployeeEducation.grade"],
      description: education["EmployeeEducation.description"],
      universityName: education["EmployeeEducation.universityName"],
      employeeEducationId: education["EmployeeEducation.employeeEducationId"],
      employeeId: education["EmployeeEducation.employeeId"],
      visibleToEmployee: education["EmployeeEducation.visibleToEmployee"],
      approved: education["EmployeeEducation.approved"],
    };
    return obj;
  }

  const API = {
    delete: `${employeeEducation}`,
    download: `${educationDocDownload}`,
  };

  function getImagePath() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2] + "/education.png";
    return newPath;
  }

  const handleEducationAdded = async () => {
    await getEmployeeEducationDetails();
    setOpenDialog(false);
    setReload((prev) => !prev);
  };

  const handleEducationDeleted = async () => {
    await getEmployeeEducationDetails();
    setReload((prev) => !prev);
  };

  const options = getActionsObject(educationDetails, cubeModelName);

  return (
    <>
      <Container>
        <div className="formBlock">
          <Row>
            <Col>
              <h4
                className="CardTittle py-2"
                style={{ textAlign: "left" }}>
                <IntlMessages id="emp.edu.tittle" />
              </h4>
            </Col>
            <Col className="text-end py-2">
              <Button
                className="btn-responsive"
                size="small"
                color="primary"
                onClick={() => setOpenDialog(true)}>
                Add
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      {openDialog && (
        <AddEducationInfo
          employeeId={employeeID}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          refresh={handleEducationAdded}
          rowData={rowData}
          setRowData={setRowData}
        />
      )}
      {isLoading && <RctSectionLoader />}
      <Container>
        <Row>
          {educationDetails.map((education) => {
            const eduObj = getEducationDetailObj(education);
            const setSelectedRowId = eduObj.employeeEducationId;
            return (
              <Col
                md={12}
                key={eduObj.employeeEducationId}>
                <div
                  style={{ marginBottom: "15px" }}
                  className="card ">
                  <div className="card-header  d-flex">
                    <Col className="col-4 col-md-10 d-flex">
                      <div className="imageWrapper">
                        <img
                          src={getImagePath()}
                          alt="Education"
                        />
                      </div>
                      <div style={{ paddingTop: "5px", marginLeft: "5px" }}>
                        <h6> {eduObj.courseName} </h6>
                      </div>
                    </Col>
                    <Col className="text-end">
                      {" "}
                      <CompactMenuWithActions
                        options={options}
                        rowData={eduObj}
                        cubeModelName={cubeModelName}
                        popupComponent={AddEducationInfo}
                        showEditComponent={!eduObj.approved}
                        ShowDeleteComponent={!eduObj.approved}
                        Id={setSelectedRowId}
                        api={API}
                        message={"Education Details"}
                        cubeModelId={setSelectedRowId}
                        reloadTable={handleEducationDeleted}
                        ShowStatus={false}
                        showAttachments={true}
                        documentType={DOCUMENT_TYPES.EDUCATION_DOCUMENT}
                      />
                    </Col>
                  </div>

                  <div className="card-body">
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        xs={12}>
                        <Row>
                          <Col>
                            <div className="title">
                              <IntlMessages id="emp.edu.qualificationType" />
                            </div>
                            <div className="disable-div"> {eduObj.qualificationType}</div>
                          </Col>
                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.courseType" />
                            </div>
                            <div className="disable-div">{eduObj.courseType}</div>
                          </Col>

                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.courseStartDate" />
                            </div>
                            <div className="disable-div">{eduObj.courseStartDate}</div>
                          </Col>
                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.courseEndDate" />
                            </div>
                            <div className="disable-div">{eduObj.courseEndDate}</div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.grade" />
                            </div>
                            <div className="disable-div">{eduObj.grade || "-"}</div>
                          </Col>
                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.stream" />
                            </div>
                            <div className="disable-div">{eduObj.stream || "-"}</div>
                          </Col>

                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.collegeName" />
                            </div>
                            <div className="disable-div">{eduObj.collegeName}</div>
                          </Col>
                          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                            <div className="title">
                              <IntlMessages id="emp.edu.universityName" />
                            </div>
                            <div className="edu-description">{eduObj.universityName || "-"}</div>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col
                            lg={12}
                            md={12}
                            xs={12}>
                            <div className="title">
                              <IntlMessages id="emp.edu.description" />
                            </div>
                            <div className="edu-description">{eduObj.description || "-"}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default EducationInfo;
