import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { AxiosInstance } from "../../config/AxiosConfig";
import { employeeEducation } from "../../redux/ApiLists";
import { getAllEnumsAction } from "../../redux/actions";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import { FormFooter } from "../shared/fromFooter/FromFooter";
import { removeTimeFromDate } from "../../util/Helper";
import PropTypes from "prop-types";

const AddEducationInfo = ({ employeeId, openDialog, setOpenDialog, refresh, reloadTable, rowData }) => {
  AddEducationInfo.propTypes = {
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    refresh: PropTypes.func.isRequired,
    reloadTable: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
  };
  const [allEnums, setAllEnums] = useState({});
  const [loading, setLoading] = useState(false);
  const [hideShowDate] = useState(false);
  const educationId = rowData?.employeeEducationId;
  let validationSchema = yup.object().shape({
    qualificationType: yup.string().trim().required(""),
    courseType: yup.string().trim().required(""),
    collegeName: yup.string().trim().required(""),
    universityName: yup.string().trim().required(""),
    courseStartDate: !hideShowDate ? yup.string().trim().required("") : "",
    courseName: yup.string().trim().required(""),
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      employeeEducationId: "",
      employeeId: employeeId,
      qualificationType: "",
      courseType: "",
      courseName: "",
      stream: "",
      courseStartDate: "",
      courseEndDate: "",
      collegeName: "",
      grade: "",
      description: "",
      universityName: "",
      visibleToEmployee: true,
      approved: false,
    },
    onSubmit: (values) => {
      if (values.courseStartDate == "" || values.courseEndDate == "") {
        return;
      }
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    formik.setSubmitting(false);
  }

  const [educationData, setEducationData] = useState(formik.values);

  const createEducationInfo = async (values) => {
    setLoading(true);
    await AxiosInstance.post(employeeEducation, values)
      .then((response) => {
        setOpenDialog(false);
        refresh();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const updateEducationInfo = async (values) => {
    setLoading(true);
    await AxiosInstance.put(`${employeeEducation}/${educationId}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadTable();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const handleSubmit = async (formData) => {
    if (educationId) {
      updateEducationInfo(formData);
    } else {
      createEducationInfo(formData);
    }
  };
  const courseEndDate = formik.values.courseStartDate;
  useEffect(() => {
    if (rowData) {
      setEducationData({
        employeeEducationId: rowData.employeeEducationId,
        employeeId: rowData.employeeId,
        qualificationType: rowData.qualificationType,
        courseType: rowData.courseType,
        courseName: rowData.courseName,
        stream: rowData.stream,
        courseStartDate: removeTimeFromDate(rowData.courseStartDate),
        courseEndDate: removeTimeFromDate(rowData.courseEndDate),
        collegeName: rowData.collegeName,
        grade: rowData.grade,
        description: rowData.description,
        universityName: rowData.universityName,
        visibleToEmployee: rowData.visibleToEmployee,
        approved: rowData.approved,
      });
    }
  }, [rowData]);

  useEffect(() => {
    formik.setValues(educationData);
  }, [educationData]);

  const AllEnumsStore = useSelector((store) => {
    return store?.utilityControllerReducer?.utilAllEnums;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!AllEnumsStore) {
      dispatch(getAllEnumsAction());
    } else if (Object.keys(AllEnumsStore).length > 0) {
      setAllEnums(AllEnumsStore);
    }
  }, [AllEnumsStore]);

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  return (
    <div>
      <ModelSelfFooter
        open={openDialog}
        heading={"Add Education Detail"}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => {
          setOpenDialog(false);
        }}
        maxWidth="md"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="employeeForm mdForm"
          onSubmit={formik.handleSubmit}>
          <div>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="qualificationType"
                    className="requiredField">
                    <IntlMessages id="emp.edu.qualificationType" />
                  </Label>
                  <Input
                    id="qualificationType"
                    type="select"
                    name="qualificationType"
                    onChange={formik.handleChange}
                    value={formik.values.qualificationType}
                    className="input-select-field-css"
                    invalid={formik.touched.qualificationType && !formik.values.qualificationType}
                    onBlur={formik.handleBlur}>
                    <option value="">-- Select --</option>
                    {allEnums?.qualificationType &&
                      Object.values(allEnums.qualificationType).map((value) => (
                        <option
                          key={"qualificationType"}
                          value={value}>
                          {value}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label
                    htmlFor="courseName"
                    className="requiredField">
                    <IntlMessages id="emp.edu.courseName" />
                  </Label>
                  <Input
                    id="courseName"
                    name="courseName"
                    type="text"
                    className="input-field-css"
                    invalid={formik.touched.courseName && !formik.values.courseName}
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      const newValue = currentValue.replace(/^ /, ""); // remove leading space
                      if (newValue !== currentValue) {
                        e.target.value = newValue;
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.courseName}
                  />
                </FormGroup>
              </Col>

              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label
                    htmlFor="courseType"
                    className="requiredField">
                    <IntlMessages id="emp.edu.courseType" />
                  </Label>
                  <Input
                    id="courseType"
                    name="courseType"
                    type="select"
                    onChange={formik.handleChange}
                    value={formik.values.courseType}
                    className="input-select-field-css"
                    invalid={formik.touched.courseType && !formik.values.courseType}
                    onBlur={formik.handleBlur}>
                    <option value="">-- Select --</option>
                    {allEnums?.courseType &&
                      Object.values(allEnums.courseType).map((value) => (
                        <option
                          key={"courseType"}
                          value={value}>
                          {value}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="courseStartDate"
                    className="requiredField">
                    <IntlMessages id="emp.edu.courseStartDate" />
                  </Label>
                  <Input
                    id="courseStartDate"
                    name="courseStartDate"
                    type="month"
                    onChange={formik.handleChange}
                    value={formik?.values?.courseStartDate?.includes("T") ? removeTimeFromDate(formik?.values?.courseStartDate) : formik?.values?.courseStartDate}
                    className="input-field-css"
                    invalid={formik.touched.courseStartDate && !formik.values.courseStartDate}
                    onBlur={formik.handleBlur}
                  />
                  {renderErrorMessage("courseStartDate")}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    htmlFor="courseEndDate"
                    className="requiredField">
                    <IntlMessages id="emp.edu.courseEndDate" />
                  </Label>
                  <Input
                    id="courseEndDate"
                    name="courseEndDate"
                    type="month"
                    onChange={formik.handleChange}
                    value={formik.values.courseEndDate}
                    min={courseEndDate}
                    className="input-field-css"
                    invalid={formik.touched.courseEndDate && !formik.values.courseEndDate}
                    onBlur={formik.handleBlur}
                  />
                  {renderErrorMessage("courseEndDate")}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label htmlFor="grade">
                    <IntlMessages id="emp.edu.grade" />
                  </Label>
                  <Input
                    id="grade"
                    name="grade"
                    type="text"
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      const newValue = currentValue.replace(/^ /, "");
                      if (newValue !== currentValue) {
                        e.target.value = newValue;
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.grade}
                  />
                </FormGroup>
              </Col>
              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label htmlFor="stream">
                    <IntlMessages id="emp.edu.stream" />
                  </Label>
                  <Input
                    id="stream"
                    name="stream"
                    type="text"
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      const newValue = currentValue.replace(/^ /, "");
                      if (newValue !== currentValue) {
                        e.target.value = newValue;
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.stream}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label
                    className="requiredField"
                    htmlFor="collegeName">
                    <IntlMessages id="emp.edu.collegeName" />
                  </Label>
                  <Input
                    id="collegeName"
                    name="collegeName"
                    type="text"
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      const newValue = currentValue.replace(/^ /, "");
                      if (newValue !== currentValue) {
                        e.target.value = newValue;
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.collegeName}
                    className="input-field-css"
                    invalid={formik.touched.collegeName && !formik.values.collegeName}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </Col>

              <Col
                md={6}
                sm={12}>
                <FormGroup>
                  <Label
                    className="requiredField"
                    htmlFor="universityName">
                    <IntlMessages id="emp.edu.universityName" />
                  </Label>
                  <Input
                    id="universityName"
                    name="universityName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.universityName}
                    className="input-field-css"
                    invalid={formik.touched.universityName && !formik.values.universityName}
                    onBlur={formik.handleBlur}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="description">
                    <IntlMessages id="emp.edu.description" />
                  </Label>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control"
                    rows="2"
                    id="description"
                    onChange={(e) => {
                      const currentValue = e.target.value;
                      const newValue = currentValue.replace(/^ /, "");
                      if (newValue !== currentValue) {
                        e.target.value = newValue;
                      }
                      formik.handleChange(e);
                    }}
                    value={formik.values.description}></textarea>
                </FormGroup>
              </Col>
            </Row>
          </div>

          <FormFooter
            loading={loading}
            setOpenDialog={setOpenDialog}
          />
        </Form>
      </ModelSelfFooter>
    </div>
  );
};

export default AddEducationInfo;
