import Axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import assetImg from "../../assets/images/assetImage.jpg";
import defaultcompanylogo from "../../assets/images/defaultcompanylogo.png";
import EmployeeImage from "../../assets/images/empImg.png";
import { getAccessToken, getCompanyCode } from "../authentication/HandleCookies";
const ProfilePic = ({ Id, assetDefaultImg, urls, forDefaultImg, load, forCompanyDefault, empPic, withId, loadCompanyLogo }) => {
  ProfilePic.propTypes = {
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    assetDefaultImg: PropTypes.string,
    urls: PropTypes.object,
    forDefaultImg: PropTypes.bool,
    load: PropTypes.bool,
    forCompanyDefault: PropTypes.bool,
    empPic: PropTypes.bool,
    withId: PropTypes.bool,
    loadCompanyLogo: PropTypes.func,
  };

  const imageUrl = !empPic ? `${urls.download}/${Id}` : urls.download;
  const imageUrlR = withId ? `${urls.download}/${Id}` : urls.download;
  const [imgSrc, setImgSrc] = useState();

  const ShowImage = ({ imageURL }) => {
    ShowImage.propTypes = {
      imageURL: PropTypes.string,
    };
    return (
      <>
        {(() => {
          if (imageURL) {
            return (
              <img
                style={{ verticalAlign: "top", maxWidth: "100%", maxHeight: "100%" }}
                src={imageURL}
                alt=""
                onerror="this.style.display = 'none';"
              />
            );
          } else if (forDefaultImg) {
            return (
              <img
                style={{ verticalAlign: "top", maxWidth: "100%", maxHeight: "100%" }}
                src={EmployeeImage}
                alt=""
                onerror="this.style.display = 'none';"
              />
            );
          } else if (assetDefaultImg) {
            return (
              <img
                style={{ verticalAlign: "top", maxWidth: "100%", maxHeight: "100%" }}
                src={assetImg}
                alt=""
                onerror="this.style.display = 'none';"
              />
            );
          } else if (forCompanyDefault) {
            return (
              <img
                style={{ verticalAlign: "top", maxWidth: "100%", maxHeight: "100%" }}
                src={defaultcompanylogo}
                alt=""
                onerror="this.style.display = 'none';"
              />
            );
          } else {
            return;
          }
        })()}
      </>
    );
  };
  const loadImage = (uri) => {
    Axios.get(`${process.env.REACT_APP_ERMS_EMPLOYEE_API}${uri}`, {
      responseType: "blob",
      headers: {
        "X-COMPANY-CODE": getCompanyCode(),
        authorization: "Bearer " + getAccessToken(),
      },
    })
      .then((response) => {
        const fileURL = URL.createObjectURL(response.data);
        response?.data?.size > 0 && setImgSrc(fileURL);
      })
      .catch((e) => {});
    setImgSrc(null);
  };

  useEffect(() => {
    if (load && loadCompanyLogo) {
      loadImage(imageUrl);
      dispatch(getCompanyLogo(false));
    } else if (forDefaultImg) {
      loadImage(imageUrl);
    } else if (assetDefaultImg) {
      loadImage(imageUrl);
    } else {
      loadImage(imageUrlR);
    }
  }, [imageUrl, imageUrlR, load, forDefaultImg, assetDefaultImg]);

  return (
    <ShowImage
      imageURL={imgSrc}
      assetDefaultImg={assetDefaultImg}
      forDefaultImg={forDefaultImg}
      forCompanyDefault={forCompanyDefault}
    />
  );
};

export default ProfilePic;
