import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/authentication/Login";
import Landingpage from "./LandingPage";
import PageNotFound from "./dashboard/pageNotFound";
import ToastMessage from "./shared/ToastMessage";
import { getAccessToken } from "./authentication/HandleCookies";

const WrapperComponent = () => {
  const [pageError, setPageError] = useState(false);
  const token = getAccessToken();
  return (
    <>
      <div className="WrapperComponent">
        <ToastMessage />
        <Routes>
          <Route
            path="/login"
            element={token ? <Navigate to="/dashboard" /> : <Login />}
          />
          {pageError && (
            <Route
              path="/pageNotFound"
              element={<PageNotFound />}
            />
          )}
        </Routes>
        <Landingpage setPageError={setPageError} />
      </div>
    </>
  );
};
export default WrapperComponent;
