import { CubeContext } from "@cubejs-client/react";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { AxiosInstance } from "../../config/AxiosConfig";
import { getLeaveRecordList, workDaySettingApi } from "../../redux/ApiLists";
import { getAllEnumsAction } from "../../redux/actions/utilityControllerAction";
import IntlMessages from "../../util/IntlMessages";
import { LEAVE, LEAVE_RECORD, LEAVE_TYPES } from "../shared/Enums";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import { FormFooter } from "../shared/fromFooter/FromFooter";
import { getProfile } from "../authentication/HandleCookies";
import { removeTimeFromDate } from "../../util/Helper";

const AddLeaveRecord = ({ openDialog, setOpenDialog, reloadTable, rowData }) => {
  AddLeaveRecord.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    reloadTable: PropTypes.func,
  };
  const { cubejsApi } = useContext(CubeContext);
  let weekDay = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const leaveRecordSchema = "LeaveRecord";
  const leaveBalanceSchema = "LeaveBalance";
  const holidaySchema = "Holiday";
  const profile = JSON.parse(getProfile());
  const employeeId = profile.attributes.employee_id;
  const [loading, setLoading] = useState(false);
  const [allEnums, setAllEnums] = useState([]);
  const [leaveRecords, setLeaveRecords] = useState([]);
  const [workDays, setWorkDays] = useState({});
  const [holidays, setHolidays] = useState([]);
  const [halfDaySelected, setHalfDaySelected] = useState(false);
  const leaveRecordById = rowData ? rowData[`${leaveRecordSchema}.leaveRecordId`] : undefined;
  const [appliedLeave, setAppliedLeave] = useState({
    annualApplied: 0,
    casualApplied: 0,
    sickApplied: 0,
    paternityApplied: 0,
    maternityApplied: 0,
    compensatoryApplied: 0,
    unpaidApplied: 0,
  });
  const [availableLeave, setAvailableLeave] = useState({
    annual: 0,
    casual: 0,
    sick: 0,
    maternity: 0,
    paternity: 0,
    compensatory: 0,
  });

  const leaveBalanceInitialState = {
    year: new Date().getFullYear(),
    currentYearAnnualLeave: 0,
    sickLeave: 0,
    maternityLeave: 0,
    paternityLeave: 0,
    totalAnnualLeave: 0,
    previousYearAnnualLeave: 0,
    maxAccruedLimit: 0,
    annualAvailed: 0,
    sickAvailed: 0,
    maternityAvailed: 0,
    paternityAvailed: 0,
    sickPayoutPercent: 0,
    maternityPayoutPercent: 0,
    paternityPayoutPercent: 0,
    unpaidAvailed: 0,
    annualPayoutPercent: 0,
    casualLeave: 0,
    compensatoryLeave: 0,
    compensatoryAvailed: 0,
    compensatoryPayoutPercent: 0,
    casualAvailed: 0,
  };
  useEffect(() => {
    getHolidayOfCurrentYear();
    getWorkDaySetting();
  }, []);

  const [leaveBalanceData, setLeaveBalanceData] = useState(leaveBalanceInitialState);
  const validValue = (key) => {
    return rowData?.[key] ? removeTimeFromDate(rowData[key]) : "";
  };
  let validationSchema = yup.object().shape({
    fromDate: yup.date().required(""),
    toDate: yup.date().required(""),
    daysOff: yup.string().required(""),
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      leaveRecordId: "",
      employeeId: employeeId,
      empCode: validValue(`${leaveRecordSchema}.empCode`),
      empName: validValue(`${leaveRecordSchema}.leaveInterval`),
      fromDate: validValue(`${leaveRecordSchema}.fromDate`),
      toDate: validValue(`${leaveRecordSchema}.toDate`),
      leaveType: validValue(`${leaveRecordSchema}.leaveType`),
      daysOff: validValue(`${leaveRecordSchema}.daysOff`),
      leaveReason: validValue(`${leaveRecordSchema}.leaveReason`),
      comment: validValue(`${leaveRecordSchema}.comment`),
      leaveInterval: validValue(`${leaveRecordSchema}.leaveInterval`),
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const LEAVE_RECORD_QUERY = {
    dimensions: [
      `${leaveRecordSchema}.leaveRecordId`,
      `${leaveRecordSchema}.employeeId`,
      `${leaveRecordSchema}.fromDate`,
      `${leaveRecordSchema}.toDate`,
      `${leaveRecordSchema}.leaveType`,
      `${leaveRecordSchema}.daysOff`,
      `${leaveRecordSchema}.leaveReason`,
      `${leaveRecordSchema}.leaveStatus`,
      `${leaveRecordSchema}.comment`,
      `${leaveRecordSchema}.leaveInterval`,
    ],

    filters: [
      {
        member: `${leaveRecordSchema}.employeeId`,
        operator: "equals",
        values: [formik.values.employeeId],
      },
      {
        member: `${leaveRecordSchema}.leaveStatus`,
        operator: "equals",
        values: ["Applied"],
      },
    ],
  };

  const HOLIDAY_LIST_QUERY = {
    dimensions: ["Holiday.event", "Holiday.date", "Holiday.comment", "Holiday.holidayId"],
    order: {
      "Holiday.event": "asc",
    },
    timeDimensions: [
      {
        dimension: "Holiday.date",
        granularity: "year",
        dateRange: "This year",
      },
    ],
  };

  const calculateDaysOff = () => {
    if (Object.keys(workDays).length > 0) {
      let holidayAndWeekEndCount = 0;
      let isSameDayHolidayAndWeekend = 0;
      const millisInDay = 1000 * 60 * 60 * 24;
      let fromDate = new Date(formik.values.fromDate);
      fromDate.setHours(0, 0, 0, 0);
      let toDate = new Date(halfDaySelected ? formik.values.fromDate : formik.values.toDate);
      toDate.setHours(0, 0, 0, 0);

      const diffTime = Math.abs(fromDate - toDate);
      const totalAppliedLeaveDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      for (let i = 0; i < totalAppliedLeaveDays; i++) {
        let date = new Date(fromDate.getTime() + millisInDay * i);
        holidays.map((e) => {
          const holiday = new Date(e[`${holidaySchema}.date`]);
          if (holiday.valueOf() == date.valueOf()) {
            holidayAndWeekEndCount++;
            if (!workDays[weekDay[date.getDay()]]) {
              isSameDayHolidayAndWeekend++;
            }
          }
          return null;
        });
      }

      for (let i = 0; i < totalAppliedLeaveDays; i++) {
        let date = new Date(fromDate.getTime() + millisInDay * i);
        let day = weekDay[date.getDay()];
        if (!workDays[day]) {
          holidayAndWeekEndCount++;
        }
      }

      let daysOff = totalAppliedLeaveDays - (holidayAndWeekEndCount - isSameDayHolidayAndWeekend);
      formik.setFieldValue("daysOff", daysOff);
      return daysOff;
    } else {
      rowData ? "" : toast.error(<IntlMessages id="leave.record.error.message" />);
    }
  };

  const getHolidayOfCurrentYear = async () => {
    await cubejsApi.load(HOLIDAY_LIST_QUERY).then((resultSet) => {
      const response = resultSet.tablePivot();
      if (response.length > 0) {
        setHolidays(response);
      } else {
        setLeaveBalanceData([]);
      }
    });
  };

  const getWorkDaySetting = async () => {
    await AxiosInstance.get(`${workDaySettingApi}`).then((response) => {
      setWorkDays(response);
    });
  };

  const LEAVE_BALANCE_QUERY = {
    dimensions: [
      `${leaveBalanceSchema}.year`,
      `${leaveBalanceSchema}.employeeId`,
      `${leaveBalanceSchema}.currentYearAnnualLeave`,
      `${leaveBalanceSchema}.sickLeave`,
      `${leaveBalanceSchema}.maternityLeave`,
      `${leaveBalanceSchema}.paternityLeave`,
      `${leaveBalanceSchema}.totalAnnualLeave`,
      `${leaveBalanceSchema}.previousYearAnnualLeave`,
      `${leaveBalanceSchema}.maxAccruedLimit`,
      `${leaveBalanceSchema}.annualAvailed`,
      `${leaveBalanceSchema}.sickAvailed`,
      `${leaveBalanceSchema}.maternityAvailed`,
      `${leaveBalanceSchema}.paternityAvailed`,
      `${leaveBalanceSchema}.sickPayoutPercent`,
      `${leaveBalanceSchema}.maternityPayoutPercent`,
      `${leaveBalanceSchema}.paternityPayoutPercent`,
      `${leaveBalanceSchema}.unpaidAvailed`,
      `${leaveBalanceSchema}.annualPayoutPercent`,
      `${leaveBalanceSchema}.casualLeave`,
      `${leaveBalanceSchema}.casualAvailed`,
      `${leaveBalanceSchema}.compensatoryLeave`,
      `${leaveBalanceSchema}.compensatoryAvailed`,
      `${leaveBalanceSchema}.compensatoryPayoutPercent`,
    ],

    filters: [
      {
        member: `${leaveBalanceSchema}.employeeId`,
        operator: "equals",
        values: [formik.values.employeeId],
      },
      {
        member: `${leaveBalanceSchema}.year`,
        operator: "equals",
        values: [new Date().getFullYear().toString()],
      },
    ],
  };

  const getLeaveBalanceData = async () => {
    if (!formik.values.employeeId == "") {
      await cubejsApi.load(LEAVE_BALANCE_QUERY).then((resultSet) => {
        const response = resultSet.tablePivot();
        if (response.length > 0) {
          setLeaveBalanceDataIntoState(response[0]);
        } else {
          setLeaveBalanceData(leaveBalanceInitialState);
        }
      });
    }
  };

  const setLeaveBalanceDataIntoState = (response) => {
    setLeaveBalanceData(() => ({
      year: response[`${leaveBalanceSchema}.year`],
      currentYearAnnualLeave: response[`${leaveBalanceSchema}.currentYearAnnualLeave`],
      sickLeave: response[`${leaveBalanceSchema}.sickLeave`],
      maternityLeave: response[`${leaveBalanceSchema}.maternityLeave`],
      paternityLeave: response[`${leaveBalanceSchema}.paternityLeave`],
      totalAnnualLeave: response[`${leaveBalanceSchema}.totalAnnualLeave`],
      previousYearAnnualLeave: response[`${leaveBalanceSchema}.previousYearAnnualLeave`],
      maxAccruedLimit: response[`${leaveBalanceSchema}.maxAccruedLimit`],
      annualAvailed: response[`${leaveBalanceSchema}.annualAvailed`],
      sickAvailed: response[`${leaveBalanceSchema}.sickAvailed`],
      maternityAvailed: response[`${leaveBalanceSchema}.maternityAvailed`],
      paternityAvailed: response[`${leaveBalanceSchema}.paternityAvailed`],
      sickPayoutPercent: response[`${leaveBalanceSchema}.sickPayoutPercent`],
      maternityPayoutPercent: response[`${leaveBalanceSchema}.maternityPayoutPercent`],
      paternityPayoutPercent: response[`${leaveBalanceSchema}.paternityPayoutPercent`],
      unpaidAvailed: response[`${leaveBalanceSchema}.unpaidAvailed`],
      annualPayoutPercent: response[`${leaveBalanceSchema}.annualPayoutPercent`],
      casualLeave: response[`${leaveBalanceSchema}.casualLeave`],
      compensatoryLeave: response[`${leaveBalanceSchema}.compensatoryLeave`],
      compensatoryAvailed: response[`${leaveBalanceSchema}.compensatoryAvailed`],
      compensatoryPayoutPercent: response[`${leaveBalanceSchema}.compensatoryPayoutPercent`],
      casualAvailed: response[`${leaveBalanceSchema}.casualAvailed`],
    }));
  };

  useEffect(() => {
    let annual = 0;
    let casual = 0;
    let sick = 0;
    let paternity = 0;
    let maternity = 0;
    let compensatory = 0;
    let unpaid = 0;

    leaveRecords?.forEach((obj) => {
      switch (obj[LEAVE_RECORD.leaveType]) {
        case LEAVE_TYPES.ANNUAL_LEAVE:
          annual += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.CASUAL_LEAVE:
          casual += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.SICK_LEAVE:
          sick += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.MATERNITY_LEAVE:
          maternity += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.PATERNITY_LEAVE:
          paternity += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.COMPENSATORY_LEAVE:
          compensatory += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        case LEAVE_TYPES.UNPAID_LEAVE:
          unpaid += obj[LEAVE_RECORD.daysOff] || 0;
          break;
        default:
          break;
      }
    });

    setAppliedLeave((prev) => ({
      ...prev,
      annualApplied: annual,
      casualApplied: casual,
      sickApplied: sick,
      maternityApplied: maternity,
      paternityApplied: paternity,
      compensatoryApplied: compensatory,
      unpaidApplied: unpaid,
    }));
  }, [leaveRecords]);

  useEffect(() => {
    getLeaveRecords();
    getLeaveBalanceData();
  }, [formik.values.employeeId]);

  const getLeaveRecords = async () => {
    if (!formik.values.employeeId == "") {
      await cubejsApi.load(LEAVE_RECORD_QUERY).then((resultSet) => {
        const response = resultSet.tablePivot();
        setLeaveRecords(response);
      });
    }
  };
  useEffect(() => {
    setAvailableLeave((prev) => ({
      annual: leaveBalanceData.totalAnnualLeave - leaveBalanceData.annualAvailed,
      casual: leaveBalanceData.casualLeave - leaveBalanceData.casualAvailed,
      sick: leaveBalanceData.sickLeave - leaveBalanceData.sickAvailed,
      maternity: leaveBalanceData.maternityLeave - leaveBalanceData.maternityAvailed,
      paternity: leaveBalanceData.paternityLeave - leaveBalanceData.paternityAvailed,
      compensatory: leaveBalanceData.compensatoryLeave - leaveBalanceData.compensatoryAvailed,
    }));
  }, [leaveBalanceData]);

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const CreateLeaveRecord = async (value) => {
    setLoading(true);
    await AxiosInstance.post(`${getLeaveRecordList}`, value)
      .then((response) => {
        setOpenDialog(false);
        reloadTable((prev) => !prev);
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };
  const UpdateLeaveRecord = async (value) => {
    setLoading(true);
    await AxiosInstance.put(`${getLeaveRecordList}/${leaveRecordById}`, value)
      .then((response) => {
        setOpenDialog(false);
        reloadTable();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const handleSubmit = async (value) => {
    if (leaveRecordById) {
      UpdateLeaveRecord(value);
    } else {
      CreateLeaveRecord(value);
    }
  };
  const dispatch = useDispatch();
  // Get All E-Nums
  const AllEnumsStore = useSelector((store) => {
    return store?.utilityControllerReducer?.utilAllEnums;
  });
  useEffect(() => {
    if (!AllEnumsStore) {
      dispatch(getAllEnumsAction());
    } else if (Object.keys(AllEnumsStore).length > 0) {
      setAllEnums(AllEnumsStore?.leaveTypeEnumList);
    }
  }, [AllEnumsStore]);

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    formik.setSubmitting(false);
  }

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  const maxToday = yyyy + "-" + mm + "-" + dd;

  const todate = formik.values.fromDate;

  function leaveIntervalHandleChange(event) {
    if (event.target.defaultValue == LEAVE.FIRST_HALF || event.target.defaultValue == LEAVE.SECOND_HALF) {
      setHalfDaySelected(true);
      formik.setFieldValue("leaveInterval", event.target.defaultValue);
      formik.setFieldValue("toDate", formik.values.fromDate);
      let dayOff = calculateDaysOff();
      dayOff == 0 ? formik.setFieldValue("daysOff", 0) : formik.setFieldValue("daysOff", 0.5);
    } else {
      setHalfDaySelected(false);
      formik.setFieldValue("leaveInterval", event.target.defaultValue);
      calculateDaysOff();
    }
  }

  useEffect(() => {
    if (formik.values.leaveInterval != LEAVE.FULL_DAY) {
      formik.setFieldValue("toDate", formik.values.fromDate);
      if (Object.keys(workDays).length > 0) {
        let dayOff = calculateDaysOff();
        dayOff == 0 ? formik.setFieldValue("daysOff", 0) : formik.setFieldValue("daysOff", 0.5);
      }
    }
  }, [formik.values.fromDate, formik.values.leaveInterval]);

  useEffect(() => {
    if (formik.values.leaveInterval == LEAVE.FULL_DAY && formik.values.fromDate && formik.values.toDate && !halfDaySelected) {
      const fromDate = new Date(formik.values.fromDate);
      const toDate = new Date(formik.values.toDate);
      if (fromDate > toDate) {
        formik.setFieldValue("daysOff", 0);
      } else {
        calculateDaysOff();
      }
    }
  }, [formik.values.fromDate, formik.values.toDate, formik.values.leaveInterval, halfDaySelected]);

  return (
    <div>
      <ModelSelfFooter
        open={openDialog}
        heading={rowData ? <IntlMessages id="icon.update.leave" /> : <IntlMessages id="icon.apply.leave" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col className="text-center mt-2">Leave Balance</Col>
          </Row>
          <Container className="formBlock text-center leaveBalance">
            <Row className="balanceData">
              <div className="balanceWidth">
                <Row className="leave-balance-row font-10 text-center ">
                  <Col className="col-4 p-0"></Col>
                  <Col className="p-0">
                    <IntlMessages id="form.text.leaveBalance.entitle" />
                  </Col>
                  <Col className="p-0">
                    <IntlMessages id="form.text.leaveBalance.availed" />
                  </Col>
                  <Col className="p-0">
                    <IntlMessages id="form.text.leaveBalance.applied" />
                  </Col>
                  <Col className="p-0">
                    <IntlMessages id="form.text.leaveBalance.available" />
                  </Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.totalAnnualLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.totalAnnualLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.annualAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.annualApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.annual}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.casualLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.casualLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.casualAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.casualApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.casual}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.sickLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.sickLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.sickAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.sickApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.sick}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.maternityLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.maternityLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.maternityAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.maternityApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.maternity}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.paternityLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.paternityLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.paternityAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.paternityApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.paternity}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.compensatoryLeave" />
                  </Col>
                  <Col className="text-brown">{leaveBalanceData.compensatoryLeave}</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.compensatoryAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.compensatoryApplied}</Col>
                  <Col className="text-darkCyan">{availableLeave.compensatory}</Col>
                </Row>
                <Row className="leave-balance-row">
                  <Col className="text-start col-4 font-12">
                    <IntlMessages id="leaveBalance.cols.txt.unpaidLeave" />
                  </Col>
                  <Col className="text-brown">-</Col>
                  <Col className="text-darkGreen">{leaveBalanceData.unpaidAvailed}</Col>
                  <Col className="text-navy">{appliedLeave.unpaidApplied}</Col>
                  <Col className="text-darkCyan">-</Col>
                </Row>
              </div>
            </Row>
          </Container>
          <Container>
            <Row className="mt-3">
              <Col
                xs="12"
                md="6">
                <FormGroup>
                  <Label
                    htmlFor="leaveType"
                    className="requiredField">
                    <IntlMessages id="leaveRecord.cols.txt.leaveType" />
                  </Label>
                  <Input
                    id="leaveType"
                    type="select"
                    name="leaveType"
                    onChange={formik.handleChange}
                    value={formik.values.leaveType}
                    className="input-select-field-css"
                    invalid={formik.touched.leaveType && !formik.values.leaveType}
                    onBlur={formik.handleBlur}>
                    <option value="">-- Select --</option>
                    {allEnums?.map((obj) => (
                      <option
                        key={"leaveType"}
                        value={obj}>
                        {obj}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col
                xs="12"
                md="6">
                <FormGroup>
                  <Label htmlFor="leaveReason">
                    <IntlMessages id="leaveRecord.cols.txt.leaveReason" />
                  </Label>
                  <Input
                    id="leaveReason"
                    name="leaveReason"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.leaveReason}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="container mt-2">
              <FormControl>
                <RadioGroup
                  row={true}
                  defaultValue={formik.values.leaveInterval}
                  name="radio-buttons-group"
                  onChange={leaveIntervalHandleChange}
                  value={formik.values.leaveInterval}>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}>
                    <FormControlLabel
                      value={LEAVE.FULL_DAY}
                      control={<Radio size="small" />}
                      label="Full-Day"
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}>
                    <FormControlLabel
                      value={LEAVE.FIRST_HALF}
                      control={<Radio size="small" />}
                      label="First-Half"
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}>
                    <FormControlLabel
                      value={LEAVE.SECOND_HALF}
                      control={<Radio size="small" />}
                      label="Second-Half"
                    />
                  </Col>
                </RadioGroup>
              </FormControl>
            </Row>
            <Row className="mt-3">
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label
                    htmlFor="fromDate"
                    className="requiredField">
                    <IntlMessages id="leaveRecord.cols.txt.fromDate" />
                  </Label>
                  <Input
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={removeTimeFromDate(formik.values.fromDate)}
                    min={maxToday}
                    className="input-field-css"
                    invalid={formik.touched.fromDate && !formik.values.fromDate}
                    onBlur={formik.handleBlur}
                  />
                  {renderErrorMessage("fromDate")}
                </FormGroup>
              </Col>
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label
                    htmlFor="toDate"
                    className="requiredField">
                    <IntlMessages id="leaveRecord.cols.txt.toDate" />
                  </Label>
                  <Input
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={removeTimeFromDate(formik.values.toDate)}
                    min={todate}
                    className="input-field-css"
                    invalid={formik.touched.toDate && !formik.values.toDate}
                    onBlur={formik.handleBlur}
                    disabled={formik.values.leaveInterval != LEAVE.FULL_DAY}
                  />
                  {renderErrorMessage("toDate")}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label
                    htmlFor="daysOff"
                    className="requiredField">
                    <IntlMessages id="leaveRecord.cols.txt.daysOff" />
                  </Label>
                  <Input
                    id="daysOff"
                    name="daysOff"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.daysOff}
                    className="input-field-css"
                    invalid={formik.touched.daysOff && !formik.values.daysOff}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                  {renderErrorMessage("daysOff")}
                </FormGroup>
              </Col>
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="comment">
                    <IntlMessages id="leaveRecord.cols.txt.comment" />
                  </Label>
                  <Input
                    id="comment"
                    name="comment"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
          <FormFooter
            loading={loading}
            setOpenDialog={setOpenDialog}
          />
        </Form>
      </ModelSelfFooter>
    </div>
  );
};

export default AddLeaveRecord;
