// import appLocaleData from "react-intl/locale-data/ja";
import saMessages from "../locales/ja_JA";

const jaLang = {
  messages: {
    ...saMessages,
  },
  locale: "ja-ja",
  // data: appLocaleData,
};

export default jaLang;
