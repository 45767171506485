import ListTypes from "../shared/dataTable/ListTypes";
// mapping of api names to CubeModelName
export const exportApiPath = {
  [ListTypes.employeeList]: "employee",
  [ListTypes.assetList]: "asset",
  [ListTypes.projectList]: "project",
  [ListTypes.ctcList]: "ctc",
};
export const leaveStatus = [
  { label: "leaveStatus.draft.text", status: "Draft", bgColor: " #3498db" },
  { label: "leaveStatus.submitted.text", status: "Submitted", bgColor: " #F39C12" },
  { label: "leaveStatus.approved.text", status: "Approved", bgColor: " #2ecc71" },
  { label: "leaveStatus.cancelled.text", status: "Cancelled", bgColor: " #34495e" },
  { label: "leaveStatus.declined.text", status: "Declined", bgColor: " #e74c3c" },
];
export const GrantStatus = [  
  { label: "grantStatus.draft.text", status: "Grant Draft", bgColor: " #007BFF" },
  { label: "grantStatus.grantSubmitted.text", status: "Grant Submitted", bgColor: " #F39C12" },
  { label: "grantStatus.grantApproved.text", status: "Grant Approved", bgColor: " #2ecc71" },
  { label: "grantStatus.grantDeclined.text", status: "Grant Declined", bgColor: " #e74c3c" },
  { label: "grantStatus.leaveDraft.text", status: "Leave Draft", bgColor: " #007BFF" },
  { label: "grantStatus.leaveSubmitted.text", status: "Leave Submitted", bgColor: " #F39C12" },
  { label: "grantStatus.leaveApproved.text", status: "Leave Approved", bgColor: " #2ecc71" },
  { label: "grantStatus.leaveDeclined.text", status: "Leave Declined", bgColor: " #e74c3c" },
];
export const projectResourceStatus = [
  { label: "projectResourceStatus.assigned.text", status: "Assigned", bgColor: " #F39C12" },
  { label: "projectResourceStatus.released.text", status: "Released", bgColor: " #1abc9c" },
];
export const documentStatus = [
  { label: "employeeDocumentStatus.draft.text", status: "Draft", bgColor: " #007BFF", isChecked: false },
  { label: "employeeDocumentStatus.submitted.text", status: "Submitted", bgColor: " #F39C12", isChecked: false },
  { label: "documentStatus.Approved.text", status: "Approved", bgColor: " #2ecc71", isChecked: false },
  { label: "employeeDocumentStatus.declined.text", status: "Declined", bgColor: " #e74c3c", isChecked: false },
];
export const assetStatus = [
  { label: "assetStatus.draft.text", status: "Draft", bgColor: " #3498db" },
  { label: "assetStatus.new.text", status: "New", bgColor: " #DAA520"},
  { label: "assetStatus.assigned.text", status: "Assigned", bgColor: " #F39C12" },
  { label: "assetStatus.reserved.text", status: "Reserved", bgColor: " #9b59b6" },
  { label: "assetStatus.released.text", status: "Released", bgColor: " #1abc9c" },
  { label: "assetStatus.maintenance.text", status: "Maintenance", bgColor: " #f1c40f" },
  { label: "assetStatus.available.text", status: "Available", bgColor: " #2ecc71" },
];
export const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const LEAVE_TYPES = {
  ANNUAL_LEAVE: "Annual Leave",
  CASUAL_LEAVE: "Casual Leave",
  SICK_LEAVE: "Sick Leave",
  MATERNITY_LEAVE: "Maternity Leave",
  PATERNITY_LEAVE: "Paternity Leave",
  COMPENSATORY_LEAVE: "Compensatory Leave",
  UNPAID_LEAVE: "Unpaid Leave",
};

export const LEAVE_RECORD = {
  leaveType: "LeaveRecord.leaveType",
  daysOff: "LeaveRecord.daysOff",
};
export const LEAVE = {
  FULL_DAY: "Leave",
  FIRST_HALF: "First Half",
  SECOND_HALF: "Second Half",
};
export const GrantStatusEnum = {
  GRANT_DRAFT: "Grant Draft",
  GRANT_SUBMITTED: "Grant Submitted",
  GRANT_APPROVED: "Grant Approved",
  GRANT_DECLINED: "Grant Declined",
  LEAVE_DRAFT: "Leave Draft",
  LEAVE_SUBMITTED: "Leave Submitted",
  LEAVE_APPROVED: "Leave Approved",
  LEAVE_DECLINED: "Leave Declined",
};
export const DOCUMENT_TYPES = {
  PROFILE_PHOTO: "profile-photo",
  COMPANY_LOGO: "company-logo",
  COMPANY_DOCUMENT: "company-document",
  EMPLOYEE_DOCUMENT: "employee-document",
  PROJECT_DOCUMENT: "project-document",
  PARTY_DOCUMENT: "party-document",
  INVOICE_DOCUMENT: "invoice-document",
  EXPERIENCE_DOCUMENT: "experience-document",
  EDUCATION_DOCUMENT: "education-document",
  ASSET_IMAGE: "asset-image",
  LEAVE_RECORD_DOC: "leave-record",
  COMPENSATORY_OFF: "compensatory-off",
  REIMBURSEMENT_DOCUMENT: "reimbursement-document",
  LEAVE_GRANT_DOC: "leave-grant",
  PROJECT_DOC: "project-document",
};
