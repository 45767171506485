import React, { useState } from "react";
import { monthName } from "../shared/Enums";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import PaySlipPdf from "./paySlipPdf/PaySlipPdf";
import { getActionsObject } from "../../util/Helper";
import { Col, Row } from "reactstrap";
import { getProfile } from "../authentication/HandleCookies";
import CompactMenuWithActions from "../shared/spinner/CompactMenuWithActions";

const cubeModelName = "Payslip";
const payrollCubeModelName = "Payroll";
const payrollDetailCubeModelName = "PayrollDetail";
const employeeCubeModel = "Employee";
const columnStyle = {
  minWidth: "5rem !important",
  maxWidth: "5rem !important",
};
const payslipColumns = [
  {
    text: "cols.txt.empId",
    value: `${employeeCubeModel}.employeeId`,
    type: "number",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "ID",
    value: `${payrollCubeModelName}.payrollId`,
    type: "number",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "ID",
    value: `${payrollDetailCubeModelName}.payrollDetailId`,
    type: "number",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "ID",
    value: `${cubeModelName}.payslipId`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "cols.txt.employeeFullName",
    value: `${employeeCubeModel}.fullName`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "employeeList.txt.empCode",
    value: `${employeeCubeModel}.empCode`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "employeeList.txt.designation",
    value: `${employeeCubeModel}.designation`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "employeeList.txt.dateOfJoining",
    value: `${employeeCubeModel}.dateOfJoining`,
    type: "date",
    isDisplay: false,
    isVisible: true,
    isSorting: true,
  },
  {
    text: "employeeList.txt.pan",
    value: `${employeeCubeModel}.pan`,
    type: "string",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
  },
  {
    text: "Month Year",
    value: `${payrollCubeModelName}.monthYear`,
    type: "month_year_date",
    isDisplay: true,
    isVisible: true,
    isSorting: true,
    isClickable: true,
    style: columnStyle,
  },
  {
    text: "payrollList.cols.txt.year",
    value: `${payrollCubeModelName}.year`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
    isClickable: false,
    style: columnStyle,
  },
  {
    text: "payrollList.cols.txt.month",
    value: `${payrollCubeModelName}.month`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
    isClickable: false,
    style: columnStyle,
  },
  {
    text: "payslip.cols.txt.accountNumber",
    value: `${cubeModelName}.accountNumber`,
    type: "string",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payslip.cols.txt.bankName",
    value: `${cubeModelName}.bankName`,
    type: "string",
    isDisplay: false,
    isVisible: true,
    isSorting: true,
    style: columnStyle,
  },
  {
    text: "payslip.cols.txt.ifscCode",
    value: `${cubeModelName}.ifscCode`,
    type: "string",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.paidDays",
    value: `${payrollDetailCubeModelName}.paidDays`,
    type: "string",
    isDisplay: true,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.incomeTotal",
    value: `${payrollDetailCubeModelName}.incomeTotal`,
    type: "currency",
    isDisplay: true,
    isVisible: true,
    isSorting: true,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.basicMonthly",
    value: `${payrollDetailCubeModelName}.basicMonthly`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.hraMonthly",
    value: `${payrollDetailCubeModelName}.hraMonthly`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.allowanceMonthly",
    value: `${payrollDetailCubeModelName}.allowanceMonthly`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.ltaMonthly",
    value: `${payrollDetailCubeModelName}.ltaMonthly`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.totalDeduction",
    value: `${payrollDetailCubeModelName}.deductionsTotal`,
    type: "currency",
    isDisplay: true,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.employeePfMonthly",
    value: `${payrollDetailCubeModelName}.employeePfMonthly`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.employeeEsiMonthly",
    value: `${payrollDetailCubeModelName}.employeeEsiMonthly`,
    type: "string",
    isDisplay: false,
    isVisible: true,
    isSorting: false,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.ptDeduction",
    value: `${payrollDetailCubeModelName}.ptDeduction`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: true,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.tds",
    value: `${payrollDetailCubeModelName}.tds`,
    type: "currency",
    isDisplay: false,
    isVisible: true,
    isSorting: true,
    style: columnStyle,
  },
  {
    text: "payrollDetailList.cols.txt.netSalary",
    value: `${payrollDetailCubeModelName}.netSalary`,
    type: "currency",
    isDisplay: true,
    isVisible: true,
    isSorting: true,
    style: columnStyle,
  },
  {
    text: "payslip.cols.txt.empName",
    value: `${cubeModelName}.empName`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "payslip.cols.txt.companyName",
    value: `${payrollCubeModelName}.companyName`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: false,
  },
  {
    text: "payrollList.cols.txt.payableDays",
    value: `${payrollCubeModelName}.payableDays`,
    type: "string",
    isDisplay: false,
    isVisible: false,
    isSorting: true,
    style: columnStyle,
  },
];

const PaySlipsRecordList = () => {
  const tDate = new Date();
  const yearList = [
    { label: `${tDate.getFullYear()}`, value: `${tDate.getFullYear()}`, isChecked: true },
    {
      label: `${tDate.getFullYear() - 1}`,
      value: `${tDate.getFullYear() - 1}`,
    },
    {
      label: `${tDate.getFullYear() - 2}`,
      value: `${tDate.getFullYear() - 2}`,
    },
  ];

  const [reload, reloadTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };

  const dynamicFilters = [
    {
      dimension: `${payrollDetailCubeModelName}.status`,
      operator: "equals",
      values: ["Paid"],
    },
  ];
  const defaultSortOrder = [`${payrollCubeModelName}.monthYear`, "desc"];
  const columns = [
    ...payslipColumns,

    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
      style: { position: "sticky", right:"0px", width: "0px", backgroundColor: "#fefefe" },
    },
  ];

  const actionHtml = (rowData) => {
    const options = getActionsObject(rowData, cubeModelName);
    return (
      <Row>
        <Col className="actionButtons">
          <CompactMenuWithActions
            options={options}
            rowData={rowData}
            setSelectedRowId={setSelectedRowData}
            payrollDetailId={rowData[`${payrollDetailCubeModelName}.payrollDetailId`]}
            cubeModelName={cubeModelName}
            showViewButton={true}
          />
        </Col>
      </Row>
    );
  };
  const customCols = [{ id: "action_column", component: actionHtml }];
  const customToolList = [];
  const filterOptions = [
    {
      dimension: `${payrollCubeModelName}.year`,
      name: "years",
      label: "payrollList.cols.txt.year",
      values: yearList,
      type: "checkbox",
      operator: "equals",
    },
    {
      dimension: `${payrollCubeModelName}.month`,
      name: "month",
      label: "payrollList.cols.txt.month",
      values: monthName.map((month, index) => ({
        label: month,
        value: index + 1,
      })),
      type: "dropdown",
      operator: "equals",
    },
  ];
  return (
    <>
      <TitleHeader pageTitle={"title.payslips"} />
      <DataTableWrapper
        key={`key_payslip_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.paySlipList}
        defaultFilter={defaultFilter}
        dynamicFilters={dynamicFilters}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
    </>
  );
};
export default PaySlipsRecordList;
export { payslipColumns };
