import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ListTypes from "../shared/dataTable/ListTypes";
import DocumentDetailList from "./DocumentDetailList";

const DocumentDetailDialog = ({ openDialog, setOpenDialog, entityId, documentType, disable, disableAddAndDeleteButton }) => {
  DocumentDetailDialog.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    documentType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    disableAddAndDeleteButton: PropTypes.bool.isRequired,
  };

  const cubeModelName = "DocumentDetail";

  const filterDoc = [
    {
      dimension: `${cubeModelName}.documentType`,
      operator: "equals",
      values: [`${documentType}`],
    },
    {
      dimension: `${cubeModelName}.entityId`,
      operator: "equals",
      values: [`${entityId}`],
    },
  ];

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={"Document Detail"}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => {
          setOpenDialog(false);
        }}
        maxWidth="lg"
        footer={<span className="DisplayNone">.</span>}>
        <DocumentDetailList
          disableAddAndDeleteButton={disableAddAndDeleteButton}
          entityId={entityId}
          documentType={documentType}
          filterDoc={filterDoc}
          listType={ListTypes.documentDetailDialog}
          disable={disable}
        />
      </ModelSelfFooter>
    </>
  );
};

export default DocumentDetailDialog;
