import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React from "react";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../config/AxiosConfig";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const CancelButton = ({ api, Id, message, disable, reloadTable, rowData }) => {
  CancelButton.propTypes = {
    api: PropTypes.object.isRequired,
    Id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    disable: PropTypes.string,
    reloadTable: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const cancelLeave = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to cancel this ${message}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel Leave!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let leaveRecordPayload = createPayload(rowData);
          await AxiosInstance.put(`${api.update}/${Id}`, leaveRecordPayload).then((res) => {
            reloadTable((prev) => !prev);
          });
        }
      });
  };

  const createPayload = (rowData) => {
    const leaveRecordPayload = {
      leaveRecordId: rowData[`LeaveRecord.leaveRecordId`],
      daysOff: rowData[`LeaveRecord.daysOff`],
      fromDate: rowData[`LeaveRecord.fromDate`],
      leaveReason: rowData[`LeaveRecord.leaveReason`],
      comment: rowData[`LeaveRecord.comment`],
      leaveStatus: "Cancelled",
      leaveType: rowData[`LeaveRecord.leaveType`],
      toDate: rowData[`LeaveRecord.toDate`],
      leaveInterval: rowData[`LeaveRecord.leaveInterval`],
    };
    return leaveRecordPayload;
  };

  return (
    <>
      <span className="cancelButtonSize">
        {disable != "Approved" && disable != "Cancelled" ? (
          <Tooltip
            placement="top"
            title="Cancel Leave">
            {/* <CancelOutlinedIcon
              onClick={cancelLeave}
              className="deleteIcon"
            /> */}
            <span onClick={cancelLeave} className="deleteIcon">Leave Cancel</span>
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title="Cancel Leave">
            <CancelOutlinedIcon className="disableddeleteicon" />
          </Tooltip>
        )}
      </span>
    </>
  );
};

export default CancelButton;
