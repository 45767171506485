import Axios from "axios";
// import { clearCookies } from "../.";
import { toast } from "react-toastify";
import IntlMessages from "../util/IntlMessages";
import { getAccessToken, getCompanyCode } from "../components/authentication/HandleCookies";

const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_ERMS_EMPLOYEE_API,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstance.interceptors.request.use(
  function (config) {
    let token = getAccessToken();
    config.headers["accept"] = "application/json";
    config.headers["X-COMPANY-CODE"] = getCompanyCode();
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    const { data, message, status } = response.data;
    if (status == "success") {
      if (message) {
        toast.success(<IntlMessages id={`${message}`} />);
      }
      return Promise.resolve(data);
    } else {
      if (message) {
        toast.error(<IntlMessages id={`${message}`} />);
      }
      return Promise.reject(data);
    }
  },
  function (error) {
    if (error?.request?.status == 500) {
      toast.error(<IntlMessages id={`error.message.internalServer`} />);
      return Promise.reject();
    } else if (error?.request?.status == 401 || error?.request?.status == 402 || error?.request?.status == 403) {
      toast.error(<IntlMessages id={`error.message.sessionExpired`} />);
      setTimeout(() => {
        window.location.replace("/login");
        history.push("/login");
      }, 3000);
      // clearCookies();
      return Promise.reject();
    } else if (error?.request?.status == 400) {
      const { data, message } = error.response.data;
      if (message) {
        toast.error(<IntlMessages id={`${message}`} />);
      }
      return Promise.reject(data);
    }
  }
);

const AxiosInstanceForBlob = Axios.create({
  baseURL: process.env.REACT_APP_ERMS_EMPLOYEE_API,
  //   baseURL: `http://192.168.100.13:8080/`,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstanceForBlob.interceptors.request.use(function (config) {
  let token = getAccessToken();
  // (config.method = "GET"), (config.responseType = "blob");
  // config.headers.common["X-COMPANY-CODE"] = sessionStorage.getItem("company_code");
  // config.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
  // return Promise.resolve(config);
  config.method = "GET";
  config.responseType = "blob";
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

const CancelToken = Axios.CancelToken;

export { AxiosInstance, AxiosInstanceForBlob, CancelToken };
