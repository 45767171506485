import React, { useState } from "react";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import AddEntityWrapper from "../shared/dataTable/toolbarUtils/AddEntityWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import AddLeaveRecord from "./AddLeaveRecord";
import { leaveStatus } from "../shared/Enums";
import { updateLeaveRecord } from "../../redux/ApiLists";
import { Col, Row } from "reactstrap";
import CancelButton from "../shared/CancelButton";
import { getProfile } from "../authentication/HandleCookies";

const LeaveRecordList = () => {
  const cubeModelName = "LeaveRecord";
  const [reload, reloadTable] = useState(false);
  const [, setSelectedRowData] = useState([]);
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };
  const refreshTable = () => {
    setTimeout(() => {
      reloadTable((prev) => !prev);
    }, 1500);
  };
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.fromDate`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.leaveRecordId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "leaveRecord.cols.txt.leaveType",
      value: `${cubeModelName}.leaveType`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "12rem", maxWidth: "12rem" },
    },
    {
      text: "leaveRecord.cols.txt.fromDate",
      value: `${cubeModelName}.fromDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leaveRecord.cols.txt.toDate",
      value: `${cubeModelName}.toDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leaveRecord.cols.txt.daysOff",
      value: `${cubeModelName}.daysOff`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "6rem", maxWidth: "6rem" },
    },
    {
      text: "leaveRecord.cols.txt.leaveReason",
      value: `${cubeModelName}.leaveReason`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
      defaultVal: "--",
    },
    {
      text: "leaveRecord.cols.txt.leaveStatus",
      value: `${cubeModelName}.leaveStatus`,
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isStatusColumn: true,
      statusTypeList: leaveStatus,
      isSearchable: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leaveRecord.cols.txt.comment",
      value: `${cubeModelName}.comment`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      defaultVal: "--",
    },
    {
      text: "leaveRecord.cols.txt.leaveInterval",
      value: `${cubeModelName}.leaveInterval`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      defaultVal: "--",
    },
    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
      style: { position: "sticky", right: "0px", width:"0px", backgroundColor: "#fefefe" },
    },
  ];

  const filterOptions = [
    {
      dimension: `${cubeModelName}.leaveStatus`,
      name: "leaveStatus",
      label: "leaveRecord.cols.txt.leaveStatus",
      values: leaveStatus?.map((ls) => ({ label: ls.status, value: ls.status })),
      type: "checkbox",
      operator: "equals",
    },
  ];
  const API = {
    update: `${updateLeaveRecord}`,
  };
  const actionHtml = (rowData) => {
    return (
      <Row>
        <Col className="actionButtons">
          <CancelButton
            reloadTable={refreshTable}
            api={API}
            Id={rowData[`${cubeModelName}.leaveRecordId`]}
            rowData={rowData}
            message={"Leave"}
            disable={rowData[`${cubeModelName}.leaveStatus`]}
          />
        </Col>
      </Row>
    );
  };

  const customCols = [{ id: "action_column", component: actionHtml }];

  const customToolList = [
    <AddEntityWrapper
      key=""
      reloadTable={refreshTable}
      iconTooltipId={`icon.apply.leave`}
      popupComponent={AddLeaveRecord}
    />,
  ];

  return (
    <>
      <TitleHeader pageTitle={"title.leaves"} />
      <DataTableWrapper
        key={`key_employee_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.leaveRecordList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
    </>
  );
};

export default LeaveRecordList;
