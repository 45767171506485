import CloseIcon from "@mui/icons-material/Close";
import { Alert, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import { clearPreference } from "../shared/dataTable/PreferenceHelper";

const ClearPreference = ({ openDialog, setOpenDialog }) => {
  ClearPreference.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
  };
  const handleClear = () => {
    clearPreference();
    toast.success("Preference Cleared !");
    setOpenDialog(false);
  };
  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="heading.text.clearPreference" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        footer={<span className="DisplayNone">.</span>}>
        <div className="EMSTables formBoxShadow">
          <Alert
            severity="warning"
            className="formText">
            <IntlMessages id="warning.text.clearPreference" />
          </Alert>
          <br />
        </div>
        <Row className="FooterForm">
          <Col>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleClear}>
              <IntlMessages id="button.text.clearPreference" />
            </button>
          </Col>
        </Row>
      </ModelSelfFooter>
    </>
  );
};
export default ClearPreference;
