import { CubeContext } from "@cubejs-client/react";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CardBody, CardTitle, Col, FormGroup, Row } from "reactstrap";
import { dateFormatter } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import RctSectionLoader from "../shared/spinner/RctSectionLoader";
import { getAccessToken, getProfile } from "../authentication/HandleCookies";

export default function InsuranceList() {
  const { cubejsApi } = useContext(CubeContext);
  const insuranceSchema = "InsuranceDetail";
  const employeeSchema = "Employee";
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const profile = JSON.parse(getProfile());
  const accessToken = getAccessToken();
  const employeeID = profile.employee.employeeId;

  const redirectToLogin = () => {
    window.location.href = "/login";
  };

  const INSURANCE_DETAIL_QUERY = {
    limit: 1,
    dimensions: [
      `${insuranceSchema}.insuranceDetailId`,
      `${employeeSchema}.empCode`,
      `${employeeSchema}.firstName`,
      `${employeeSchema}.lastName`,
      `${insuranceSchema}.policyHolderName`,
      `${insuranceSchema}.policyName`,
      `${insuranceSchema}.policyNumber`,
      `${insuranceSchema}.typeOfPolicy`,
      `${insuranceSchema}.sumAssured`,
      `${insuranceSchema}.premiumPaymentType`,
      `${insuranceSchema}.premiumAmount`,
      `${insuranceSchema}.policyDuration`,
      `${insuranceSchema}.expiryDateOfPolicy`,
      `${insuranceSchema}.nominee`,
      `${insuranceSchema}.relationWithNominee`,
      `${insuranceSchema}.insuranceCompany`,
    ],
    filters: [
      {
        member: `${insuranceSchema}.employeeId`,
        operator: "equals",
        values: [`${employeeID}`],
      },
    ],
  };

  const getData = async () => {
    setIsLoading(true);
    await cubejsApi
      .load(INSURANCE_DETAIL_QUERY)
      .then((resultSet) => {
        const response = resultSet.tablePivot();
        if (response?.length > 0) {
          setData(response[0]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setIsLoading(false);
  };

  const firstName = data[`${employeeSchema}.firstName`];
  const lastName = data[`${employeeSchema}.lastName`];
  const fullNameEmp = firstName + " " + lastName;

  if (employeeID && accessToken) {
    useEffect(() => {
      getData();
    }, []);

    return (
      <>
        <div className=" mb-4 columnContainer">
          <div className="col-md-6 mb-4 equalHeightColumn">
            <CardBody className="shadow p-3bg-white rounded card ">
              <CardTitle>
                <h2 className="CardTittle">
                  <IntlMessages id="myProfile.myInsurance.title" />
                </h2>
              </CardTitle>
              {isLoading && <RctSectionLoader />}
              {!!data && (
                <>
                  <Row className="mt-3">
                    <Row>
                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.employeeCode" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-6 col-md-12">
                              <div className="disable-div">{data[`${employeeSchema}.empCode`] ? data[`${employeeSchema}.empCode`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.employeeName" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{firstName && lastName ? fullNameEmp : ""}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.policyName" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.policyName`] ? data[`${insuranceSchema}.policyName`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.policyHolderName" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.policyHolderName`] ? data[`${insuranceSchema}.policyHolderName`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.policyNumber" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-6 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.policyNumber`] ? data[`${insuranceSchema}.policyNumber`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.typeOfPolicy" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.typeOfPolicy`] ? data[`${insuranceSchema}.typeOfPolicy`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.sumAssured" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.sumAssured`] ? data[`${insuranceSchema}.sumAssured`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.premiumPaymentType" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.premiumPaymentType`] ? data[`${insuranceSchema}.premiumPaymentType`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.expiryDateOfPolicy" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-6 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.expiryDateOfPolicy`] ? dateFormatter(data[`${insuranceSchema}.expiryDateOfPolicy`]) : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.nominee" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.nominee`] ? data[`${insuranceSchema}.nominee`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.relationWithNominee" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.relationWithNominee`] ? data[`${insuranceSchema}.relationWithNominee`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col className="col-12 col-sm-6 col-md-3">
                        <FormGroup>
                          <div className="detail-info-text">
                            <IntlMessages id="myProfile.myInsurance.insuranceCompany" />
                          </div>

                          <br />
                          <Row>
                            <Col className="col-12 col-sm-12 col-md-12">
                              <div className="disable-div">{data[`${insuranceSchema}.insuranceCompany`] ? data[`${insuranceSchema}.insuranceCompany`] : "-"}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                </>
              )}
            </CardBody>
          </div>
        </div>
      </>
    );
  } else {
    redirectToLogin;
  }
}
