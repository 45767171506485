import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";

const Toolbar = (props) => {
  const { className, toolList, setSearchKeyword, toolbarSize, ...rest } = props;

  Toolbar.propTypes = {
    className: PropTypes.string,
    toolList: PropTypes.array,
    setSearchKeyword: PropTypes.func,
    toolbarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return (
    <div
      {...rest}
      className="mb-2">
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        style={{ display: "flex", flexWrap: "nowrap", gap: window.innerWidth < 400 ? "4px" : "8px" }} // Reduce gap for small screens
      >
        {/* Left Side - Search Box (Now Shrinks Properly) */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          style={{
            flexGrow: 1,
            flexShrink: 1, // Allows shrinking on small screens
            minWidth: "120px", // Prevents collapse
          }}>
          {toolList[0]} {/* Search Component */}
        </Grid>

        {/* Right Side - Other Tools (No Wrapping) */}
        <Grid
          item
          container
          xs={6}
          sm={8}
          md={9}
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
          style={{ gap: window.innerWidth < 400 ? "4px" : "8px", flexWrap: "nowrap" }} // Reduced gap
        >
          {toolList.slice(1).map((tool, index) => (
            <Grid
              item
              key={index}
              style={{ minWidth: "10px" }}>
              {" "}
              {/* Reduced size for very small screens */}
              {tool}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Toolbar;
