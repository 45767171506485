import { CubeContext } from "@cubejs-client/react";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Avatar, Menu, Tooltip } from "@mui/material";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { AxiosInstance } from "../../../config/AxiosConfig";
import { companyDetailApi, empPunchStatus, empPunchTime } from "../../../redux/ApiLists";
import { calcDistance, enumPunchType, toCreateObject } from "../../../util/Helper";
import IntlMessages from "../../../util/IntlMessages";
import RctSectionLoader from "../spinner/RctSectionLoader";
import { getAccessToken, getProfile } from "../../authentication/HandleCookies";
function PresenceStatus() {
  const { cubejsApi } = useContext(CubeContext);
  const [company, setCompanyDetail] = useState();
  const [anchorElLoc, setAnchorElLoc] = useState(null);
  const isInDisable = useRef(false);
  const employeeSchema = "EmployeeDetail";
  const workLocationSchema = "WorkLocation";
  const employeeID = JSON.parse(getProfile()).employee.employeeId;
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceTime, setAttendanceTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const redirectToLogin = () => {
    window.location.href = "/login";
  };

  const WORKLOCATION_DETAIL_QUERY = {
    limit: 1,
    dimensions: [
      `${workLocationSchema}.workLocationId`,
      `${workLocationSchema}.name`,
      `${workLocationSchema}.flagLocationEnable`,
      `${workLocationSchema}.latitude`,
      `${workLocationSchema}.longitude`,
      `${workLocationSchema}.radius`,
    ],
    filters: [
      {
        member: `${employeeSchema}.employeeId`,
        operator: "equals",
        values: [`${employeeID}`],
      },
    ],
  };

  const companyInfo = JSON.parse(getProfile());
  const companyCode = companyInfo.attributes.company_code;

  const getLoggedInCompanyDetails = async () => {
    await Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}${companyDetailApi}/${companyCode}`)
      .then((result) => {
        setCompanyDetail(result.data.data);
      })
      .catch((error) => {
        toast.error(error?.message);
      }, []);
  };

  useEffect(() => {
    getLoggedInCompanyDetails();
  }, []);

  if (accessToken && employeeID) {
    useEffect(() => {
      getData();
      getTime();
    }, []);

    const handleResponse = (resp) => {
      if (resp) {
        resp.lastPunchType === enumPunchType.IN ? (isInDisable.current = true) : (isInDisable.current = false);
      } else {
        isInDisable.current = false;
      }
    };

    const getData = async () => {
      setIsLoading(true);
      await cubejsApi
        .load(WORKLOCATION_DETAIL_QUERY)
        .then((resultSet) => {
          const response = resultSet.tablePivot();
          if (response?.length > 0) {
            setData(response);
          }
        })
        .catch(() => {});

      await AxiosInstance.get(`${empPunchStatus}`)
        .then((response) => {
          handleResponse(response);
        })
        .catch(() => {});
      setIsLoading(false);
    };

    const getTime = async () => {
      setIsLoading(true);
      await AxiosInstance.get(`${empPunchTime}`)
        .then((response) => {
          if (response > 0) {
            setAttendanceTime(response);
          }
        })
        .catch(() => {});
      setIsLoading(false);
    };

    const refreshCard = () => {
      setLoading(true);

      setTimeout(() => {
        getTime();
        setLoading(false);
      }, 500);
    };

    if (isLoading) {
      return <RctSectionLoader />;
    }
    if (!isLoading && !data) {
      return null;
    }
    if (data && data.length > 0) {
      const workLocationDetail = data[0];
      const companyLocation = {
        latitude: workLocationDetail[`${workLocationSchema}.latitude`],
        longitude: workLocationDetail[`${workLocationSchema}.longitude`],
        flagLocationEnable: workLocationDetail[`${workLocationSchema}.flagLocationEnable`],
        companyLocationId: workLocationDetail[`${workLocationSchema}.workLocationId`],
        commpanyLocationName: workLocationDetail[`${workLocationSchema}.name`],
        radius: workLocationDetail[`${workLocationSchema}.radius`],
      };

      const submitPuchData = async (punchStatus, locationPunch, userLocation) => {
        setIsLoading(true);
        const dataPunch = toCreateObject(punchStatus);
        await AxiosInstance.post(`${empPunchStatus}`, {
          ...dataPunch,
          punchLocation: locationPunch === "company" ? companyLocation.commpanyLocationName : locationPunch,
          locationId: companyLocation.companyLocationId,
          userLatitude: userLocation.latitude,
          userLongitude: userLocation.longitude,
        })
          .then((response) => {
            setIsLoading(false);
            handleResponse(response);
            toast.success(`You are Check-${response.lastPunchType} now.`);
          })
          .catch(() => {
            setIsLoading(false);
            toast.error("internal Server Error");
          });
        setIsLoading(false);
      };

      const successCallback = (position) => {
        const userLocation = position.coords;
        const fleetMeter = calcDistance(userLocation.latitude, userLocation.longitude, companyLocation.latitude, companyLocation.longitude);
        const punchTypeSubmit = (locationType) => {
          if (!isInDisable.current) {
            submitPuchData(enumPunchType.IN, locationType, userLocation);
          } else {
            submitPuchData(enumPunchType.OUT, locationType, userLocation);
          }
          handleCloseLoc();
        };
        if (fleetMeter < companyLocation.radius) {
          punchTypeSubmit("company");
        } else {
          punchTypeSubmit("remote");
        }
      };

      const errorCallback = (error) => {
        // Handling errors based on error code
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            toast.error("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            toast.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            toast.error("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.");
            toast.error("An unknown error occurred.");
            break;
        }
        return;
      };

      const getUserInPremises = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      };

      const handleCloseLoc = () => {
        setAnchorElLoc(null);
      };

      const handleClickLoc = (event) => {
        setAnchorElLoc(event.currentTarget);
      };

      const FormatHoursAndMinutes = ({ hoursWorked }) => {
        FormatHoursAndMinutes.propTypes = {
          hoursWorked: PropTypes.number.isRequired,
        };
        let hours = Math.floor(hoursWorked);
        let minutes = Math.round((hoursWorked % 1) * 60);
        if (minutes === 60) {
          hours += 1;
          minutes = 0;
        }
        const paddedHours = hours.toString().padStart(2, "0");
        const paddedMinutes = minutes.toString().padStart(2, "0");

        const boxStyle = {
          width: "80px",
          height: "50px",
          border: "1px solid #ccc",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#f0f0f0",
          padding: "10px",
        };

        const centeredBoxStyle = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        };

        const formatBox = (value) => (
          <div style={boxStyle}>
            <span>{`${value}`}</span>
          </div>
        );

        return <div style={centeredBoxStyle}>{formatBox(`${paddedHours}\u00A0:\u00A0${paddedMinutes}`)}</div>;
      };

      return (
        <React.Fragment>
          <Tooltip title={<IntlMessages id="dashboard.punchStatus.toolTip" />}>
            <Avatar
              className="bgColor position"
              onClick={companyLocation.flagLocationEnable ? handleClickLoc : null}
              sx={{ width: 40, height: 40, ml: 1 }}>
              <EditLocationIcon />
            </Avatar>
          </Tooltip>

          <Menu
            anchorEl={anchorElLoc}
            id="account-menu"
            open={!!anchorElLoc}
            onClose={handleCloseLoc}
            className="myProfilePopup"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiMenu-paper": {
                position: "fixed", // Keeps the menu anchored to the viewport, not to an element
                right: 0, // Aligns it completely to the right edge
                top: anchorElLoc ? anchorElLoc.getBoundingClientRect().bottom : 0, // Positions just below the anchor element
                width: "auto", // Only as wide as content requires
                maxWidth: "100vw", // Ensures it fits within the viewport width
                marginRight: "-15px", // Removes any margin from the right
                padding: 0, // Minimizes padding around the content for a tighter fit
                boxSizing: "border-box",
              },
            }}>
            {company?.flagEmpPunchClock && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  blockSize: 50,
                }}
                onClick={refreshCard}>
                <FormatHoursAndMinutes hoursWorked={attendanceTime} />
                {loading ? (
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <BiRefresh
                      style={{
                        fontSize: "40px",
                        margin: "0 8px",
                        animation: "App-logo-spin 1s linear infinite",
                      }}
                    />
                  </div>
                ) : (
                  <BiRefresh style={{ fontSize: "40px", margin: "0 8px" }} />
                )}
              </div>
            )}

            <div className="m-2">{<IntlMessages id="dashboard.punchStatus.title" />}</div>
            <Row className="align-items-center">
              {isInDisable.current ? (
                <Col className="text-center">
                  <Button
                    disabled={!isInDisable.current}
                    color="danger"
                    onClick={() => {
                      getUserInPremises();
                    }}
                    className="customBtnPunch">
                    {<IntlMessages id="dashboard.punchStatus.outBtn" />}
                  </Button>
                </Col>
              ) : (
                <Col className="text-center">
                  <Button
                    disabled={isInDisable.current}
                    color="success"
                    onClick={() => {
                      getUserInPremises();
                    }}
                    className="customBtnPunch">
                    {<IntlMessages id="dashboard.punchStatus.inBtn" />}
                  </Button>
                </Col>
              )}
            </Row>
          </Menu>
        </React.Fragment>
      );
    }
  } else {
    redirectToLogin();
  }
}

export default PresenceStatus;
