import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddDocument from "./AddDocument";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import AddEntityWrapper from "../shared/dataTable/toolbarUtils/AddEntityWrapper";

import ListTypes from "../shared/dataTable/ListTypes";
import { deleteDocumentApi } from "../../redux/ApiLists";

import { getActionsObject, refreshTable } from "../../util/Helper";
import CompactMenuWithActions from "../shared/spinner/CompactMenuWithActions";
import { DOCUMENT_TYPES, documentStatus } from "../shared/Enums";
import { getProfile } from "../authentication/HandleCookies";

const EmpDocuments = () => {
  const cubeModelName = "EmployeeDocument";
  const [reload, reloadTable] = useState(false);
  const [, setSelectedRowData] = useState([]);
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };
  const dynamicFilters = [
    {
      dimension: `${cubeModelName}.visibleToEmployee`,
      operator: "equals",
      values: ["true"],
    },
  ];
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.documentName`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.employeeDocumentId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "documentCategoryId",
      value: `${cubeModelName}.documentCategoryId`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "employeeDocumentList.cols.txt.document",
      value: `${cubeModelName}.documentName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "employeeDocumentList.cols.txt.category",
      value: `DocumentCategory.categoryName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "employeeDocumentList.cols.txt.documentStatus",
      value: `${cubeModelName}.documentStatus`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSearchable: false,
      isStatusColumn: true,
      statusTypeList: documentStatus,
    },
    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
      style: { position: "sticky", right: "0px", width: "0px", backgroundColor: "#fefefe" },
    },
  ];
  const filterOptions = [];

  const API = {
    delete: `${deleteDocumentApi}`,
  };

  const actionHtml = (rowData) => {
    const options = getActionsObject(rowData, cubeModelName);

    return (
      <Row>
        <Col className="actionButtons">
          <CompactMenuWithActions
            options={options}
            rowData={rowData}
            reloadTable={() => refreshTable(reloadTable)}
            cubeModelName={cubeModelName}
            showAttachments={true}
            showEditComponent={false}
            ShowStatus={false}
            api={API}
            Id={rowData[`${cubeModelName}.employeeDocumentId`]}
            message={"Employee Document"}
            cubeModelId={rowData[`${cubeModelName}.employeeDocumentId`]}
            documentType={DOCUMENT_TYPES.EMPLOYEE_DOCUMENT}
            ShowDeleteComponent={true}
            disableButton={rowData[`${cubeModelName}.documentStatus`] == "Approved" ? true : false}
            disableAddAndDeleteButton={rowData[`${cubeModelName}.documentStatus`] == "Approved" ? true : false}
          />
        </Col>
      </Row>
    );
  };

  const customCols = [{ id: "action_column", component: actionHtml }];

  const customToolList = [
    <AddEntityWrapper
      reloadTable={reloadTable}
      iconTooltipId={`icon.add.Document`}
      popupComponent={AddDocument}
    />,
  ];

  return (
    <>
      <TitleHeader pageTitle={"title.myDocuments"} />
      <DataTableWrapper
        key={`key_employee_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.employeeDocumentList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
        dynamicFilters={dynamicFilters}
      />
    </>
  );
};

export default EmpDocuments;
