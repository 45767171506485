import { Checkbox, Grid, IconButton, Menu, MenuItem, Select, Tooltip, Chip, Divider, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import IntlMessages from "../../../../util/IntlMessages";
import ResponsiveDateRangePicker from "./dateRangePicker";
import PropTypes from "prop-types";
import { LuFilterX } from "react-icons/lu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filters = ({ filters, setFilters, clearDate }) => {
  Filters.propTypes = {
    filters: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    clearDate: PropTypes.bool.isRequired,
  };
  const isMediumScreen = useMediaQuery("(min-width: 320px) and (max-width: 768px)");

  const handleCheckboxFilterChange = (item, obj) => {
    let newFilters = filters.map((filter) => {
      if (filter.name == item.name) {
        filter.values = filter.values.map((val) => {
          if (val.label == obj.label) {
            val.isChecked = !val.isChecked;
          }
          return val;
        });
      }
      return filter;
    });
    setFilters(newFilters);
  };
  const handleDateFilterChange = (item, startDate, endDate) => {
    let newFilters = filters.map((filter) => {
      if (filter.name == item.name) {
        filter.values = [startDate, endDate];
      }
      if (filter.name == item.name && (!startDate || !endDate)) {
        filter.values = [];
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const handleCombinedDateFilterChange = (item, startDate, endDate) => {
    let newFilters = filters.map((filter) => {
      if (filter.name == item.name) {
        filter.values = [moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")];
      }
      if (filter.name == item.name && (!startDate || !endDate)) {
        filter.values = [];
      }
      return filter;
    });
    setFilters(newFilters);
  };
  const handleDropDownChange = (e, item) => {
    let newFilters = filters.map((filter) => {
      if (filter.name == item.name) {
        filter.values = filter.values.map((val) => {
          if (val.value == e.target.value) {
            val.isChecked = true;
          } else {
            val.isChecked = false;
          }
          return val;
        });
      }
      return filter;
    });
    setFilters(newFilters);
  };
  const getSelectedFilterValue = (item) => {
    let filter = filters.find((obj) => {
      return obj.name == item.name;
    });
    let selvalue = filter?.values.find((obj) => obj?.isChecked);
    return selvalue;
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid
        container
        justifyContent="center"
        item
        lg={1}
        sm={1}
        xl={1}
        xs={1}
        m={1}>
        <Tooltip title={<IntlMessages id="filters.tooltip.text" />}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className="filter-button-padding"
            disabled={filters.length <= 0}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className="filter">
        {!filters.length <= 0 && (
          <div className="payroll-filter-crossbtn">
            <Tooltip
              placement="top"
              title={<IntlMessages id="filters.close.popup.button" />}>
              <CloseIcon onClick={handleClose} />
            </Tooltip>
          </div>
        )}
        {filters.map((item, index) => (
          <>
            {item.type == "combinedDate" ? (
              <p className="label-item">
                <IntlMessages id={item.label1} /> - <IntlMessages id={item.label2} />
              </p>
            ) : (
              item?.type != "dropdown" && (
                <p className="label-item">
                  <IntlMessages id={item.label} />
                </p>
              )
            )}
            {item.type === "checkbox" && (
              <Row>
                {item.values
                  .slice(0, 6)
                  .reduce((rows, obj, index) => {
                    const itemsPerRow = item.values.length > 4 ? 3 : 4;
                    const currentRow = Math.floor(index / itemsPerRow);

                    if (!rows[currentRow]) rows[currentRow] = [];
                    rows[currentRow].push(
                      <Col
                        key={`${index}`}
                        lg={12}
                        md={12}
                        sm={12}>
                        <MenuItem
                          className="checkbox-item"
                          value={obj.value}
                          key={`${index}`}
                          style={{
                            width: isMediumScreen ? "8px" : "100%",
                            height: "50px",
                            display: "flex",
                          }}>
                          <Checkbox
                            color="primary"
                            checked={!!obj?.isChecked}
                            onClick={() => handleCheckboxFilterChange(item, obj)}
                          />
                          <span>{obj.label}</span>
                        </MenuItem>
                      </Col>
                    );
                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <Row key={`row-${rowIndex}`}>{row}</Row>
                  ))}
              </Row>
            )}
            {item.type === "date" && (
              <ResponsiveDateRangePicker
                item={item}
                handleDateFilterChange={handleDateFilterChange}
                clearDate={clearDate}
              />
            )}
            {item.type === "combinedDate" && (
              <ResponsiveDateRangePicker
                item={item}
                handleDateFilterChange={handleCombinedDateFilterChange}
              />
            )}
            {item.type == "dropdown" && (
              <Row className="mt-3">
                <Col className="col-4">
                  <p>
                    <IntlMessages id={item.label} />
                  </p>
                </Col>
                <Col className="col-8">
                  <Select
                    value={getSelectedFilterValue(item)}
                    onChange={(e) => handleDropDownChange(e, item)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ width: "85px", height: "32px", marginBottom: "10px", marginTop: "-5px" }}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {item.values.map((obj, i) => {
                      return (
                        <MenuItem
                          key=""
                          value={obj.value}>
                          {obj.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            )}

            {index < filters.length - 1 && <Divider />}
          </>
        ))}
      </Menu>
    </>
  );
};
export default Filters;
export const FilterChip = ({ filters, setFilters, selectedFiltersFromMenu, setClearDate }) => {
  FilterChip.propTypes = {
    filters: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    selectedFiltersFromMenu: PropTypes.array.isRequired,
    setClearDate: PropTypes.func.isRequired,
  };
  const onRemoveFilter = (item, current) => {
    let newFilters = (filters ?? []).map((filter) => {
      if (filter.dimension == item.dimension) {
        filter.values = filter.values.map((val) => {
          if (val.label === current) {
            val.isChecked = !val.isChecked;
          }
          return val;
        });
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const onRemoveDateFilter = (item, startDate, endDate) => {
    let newFilters = (filters ?? []).map((filter) => {
      if (filter.type === "date" && filter.dimension === item.dimension) {
        if (startDate || endDate) {
          filter.values = [];
          setClearDate((prev) => !prev);
        }
      }
      return filter;
    });
    setFilters(newFilters);
  };
  const clearFilters = () => {
    let newFilters = filters.map((filter) => {
      if (filter.type === "checkbox" || filter.type === "dropdown") {
        filter.values = filter.values.map((val) => {
          val.isChecked = false;
          return val;
        });
      } else if (filter.type === "date" || filter.type === "combinedDate") {
        filter.values = [];
      }
      return filter;
    });
    setFilters(newFilters);
  };
  return (
    <>
      {selectedFiltersFromMenu.length > 0 && (
        <div className="p-1">
          <div>
            <span className="webFilter">
              <IntlMessages id="filters.appliedFilter.text" />
            </span>
          </div>
          {selectedFiltersFromMenu.map((item, index) => {
            const isBooleanOperatorPresent = Object.keys(item).includes("and");
            return (
              <React.Fragment key={index}>
                {!isBooleanOperatorPresent && item.dimension.includes("month")
                  ? item.values.map((current, subIndex) => {
                      const monthName = moment()
                        .month(current - 1)
                        .format("MMM");

                      return (
                        <span
                          key={subIndex}
                          style={{ marginLeft: "4px" }}>
                          <Chip
                            size="small"
                            label={monthName}
                            onDelete={() => onRemoveFilter(item, monthName)}
                            deleteIcon={<CloseIcon fontSize="small" />}
                          />
                        </span>
                      );
                    })
                  : !isBooleanOperatorPresent && item.operator === "equals"
                  ? item.values.map((current, subIndex) => {
                      return (
                        <span
                          key={subIndex}
                          style={{ marginLeft: "4px" }}>
                          <Chip
                            size="small"
                            label={current}
                            onDelete={() => onRemoveFilter(item, current)}
                            deleteIcon={<CloseIcon fontSize="small" />}
                          />
                        </span>
                      );
                    })
                  : !isBooleanOperatorPresent && item.operator === "inDateRange"
                  ? (() => {
                      let sDate = `'From - ${moment(item.values[0]).format("DD/MM/YYYY")}'`;
                      let eDate = `'To - ${moment(item.values[1]).format("DD/MM/YYYY")}'`;
                      let current = `${sDate} - ${eDate}`;
                      return (
                        <span
                          key={""}
                          style={{ marginLeft: "4px" }}>
                          <Chip
                            size="small"
                            label={current}
                            onDelete={() => onRemoveDateFilter(item, item.values[0], item.values[1])}
                            deleteIcon={<CloseIcon fontSize="small" />}
                          />
                        </span>
                      );
                    })()
                  : null}
              </React.Fragment>
            );
          })}
          <Tooltip title={<IntlMessages id="Clear All Filters" />}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={clearFilters}
              className="clear-filters-btn">
              <LuFilterX />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};
