import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteButton from "../DeleteButton";
import EditEntityWrapper from "../dataTable/toolbarUtils/EditEntityWrapper";
import IntlMessages from "../../../util/IntlMessages";
import DocumentDetailDialog from "../../documents/DocumentDetailDailog";
import PaySlipPdf from "../../payslips/paySlipPdf/PaySlipPdf";
import { DownloadDocument } from "../DownloadDocument";
import CancelButton from "../CancelButton";

const CompactMenuWithActions = ({
  options,
  popupComponent: Component,
  rowData,
  reloadTable,
  setSelectedRowId,
  cubeModelName,
  showEditComponent,
  ShowStatus,
  ShowDeleteComponent,
  ShowCancelButton,
  showReviseCtc,
  ctcRFlag,
  ctcFlagRevision,
  showDownload,
  showViewButton,
  showDownloadDocuments,
  showAttachments,
  showViewEMI,
  showPunchDataComponent,
  disableButton,
  disableViewPayslip,
  payrollDetailId,
  onClickFunction,
  docName,
  documentType,
  api,
  Id,
  message,
  cubeModelId,
  showActionButton,
  setreloadAppUserList,
  axiosObject,
  disableAddAndDeleteButton,
  assestFile,
  disable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogAttach, setOpenDialogAttach] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAppUser, setOpenDialogAppUser] = useState(false);
  const [openDialogReviseCtc, setOpenDialogReviseCtc] = useState(false);
  if (rowData[`${cubeModelName}.id`]) {
    setSelectedRowId(cubeModelName);
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span className="action-dot-button">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </span>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "17ch",
            padding: "5px",
          },
        }}>
        <div
          className="mb-2 myElement"
          onClick={handleClose}>
          {showEditComponent == true && (
            <EditEntityWrapper
              reloadTable={reloadTable}
              rowData={rowData}
              setSelectedRowId={setSelectedRowId}
              disableButton={disableButton}
              setOpenDialog={setOpenDialog}
            />
          )}
        </div>

        <div
          className="mb-1 myElement"
          onClick={handleClose}>
          {showActionButton == true && (
            <ActionButton
              rowData={rowData}
              setreloadAppUserList={setreloadAppUserList}
              setOpenDialog={setOpenDialogAppUser}
            />
          )}
        </div>
        <div
          className="mb-1 myElement"
          onClick={handleClose}>
          {showDownload == true && (
            <div
              className="download"
              onClick={onClickFunction}
              download>
              Download
            </div>
          )}
        </div>
        <div
          className="myElement mb-1"
          onClick={handleClose}>
          {showDownloadDocuments == true && (
            <DownloadDocument
              Id={Id}
              docName={docName}
              api={api}
            />
          )}
        </div>
        {showReviseCtc && (
          <div
            className="mb-1 myElement"
            onClick={handleClose}>
            <div
              className={ctcRFlag ? (ctcFlagRevision ? "textColor" : "textColor") : "iconGrey"}
              onClick={() => ctcRFlag && setOpenDialogReviseCtc(true)}
              size="small">
              Revise CTC
            </div>
          </div>
        )}
        <div
          className="myElement"
          onClick={handleClose}>
          {ShowDeleteComponent == true && (
            <DeleteButton
              reloadTable={axiosObject ? setreloadAppUserList : reloadTable}
              api={api}
              Id={Id}
              message={message}
              rowData={rowData}
              disableButton={disableButton}
              axiosObject={axiosObject}
            />
          )}
        </div>
        <div
          className="myElement"
          onClick={handleClose}>
          {ShowCancelButton == true && (
            <CancelButton
              reloadTable={axiosObject ? setreloadAppUserList : reloadTable}
              api={api}
              Id={Id}
              message={message}
              rowData={rowData}
              disableButton={disableButton}
              axiosObject={axiosObject}
            />
          )}
        </div>
        <div className="myElement">
          {showViewButton && (
            <PaySlipPdf
              payrollDetailId={payrollDetailId}
              showViewOption={true}
              disableButton={disableViewPayslip}
            />
          )}
        </div>
        <div className="mt-1 myElement">{showViewEMI == true && <ViewLoanEmi rowData={rowData} />}</div>
        <div className="mt-1 myElement">{showPunchDataComponent == true && <PopupPunchDataModel rowData={rowData} />}</div>
        {showAttachments == true && ShowDeleteComponent == true ? <hr className="line-spacing"></hr> : ""}
        {showAttachments == true && (
          <>
            {cubeModelId && (
              <div
                color="link"
                variant="contained"
                onClick={() => setOpenDialogAttach(true)}
                className="myElement">
                <span onClick={handleClose}>
                  <div style={{ marginLeft: "14px", fontFamily: "Poppins", fontSize: "0.85rem" }}>
                    <IntlMessages id={`Attachments`} />
                  </div>
                </span>
              </div>
            )}
          </>
        )}

        {ShowStatus == true && (
          <>
            <hr className="line-spacing"></hr>
            <span onClick={handleClose}>
              {options.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => option.onClick(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </span>
          </>
        )}
      </Menu>
      {openDialog && (
        <Component
          rowData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadTable={reloadTable}
          setSelectedRowId={setSelectedRowId}
        />
      )}
      {openDialogAttach && (
        <DocumentDetailDialog
          disableAddAndDeleteButton={disableAddAndDeleteButton}
          openDialog={openDialogAttach}
          setOpenDialog={setOpenDialogAttach}
          entityId={cubeModelId}
          documentType={documentType}
          disable={disable}
          assestFile={assestFile}
        />
      )}
      {showReviseCtc == true && (
        <ReviseCtc
          rowData={rowData}
          reloadFunction={reloadTable}
          setOpenDialog={setOpenDialogReviseCtc}
          openDialog={openDialogReviseCtc}
        />
      )}
      {openDialogAppUser && (
        <RegAppUser
          appUserData={rowData}
          openDialog={openDialogAppUser}
          setOpenDialog={setOpenDialogAppUser}
          setreloadAppUserList={setreloadAppUserList}
        />
      )}
    </>
  );
};
export default CompactMenuWithActions;
