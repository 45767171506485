import { Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form, Input } from "reactstrap";
import { AxiosInstance } from "../../config/AxiosConfig";
import { documentDetailUpload } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import { toast } from "react-toastify";

const AddDocumentDetail = ({ reloadTable, iconTooltipId, entityId, documentType, disable, setIsLoading }) => {
  AddDocumentDetail.propTypes = {
    reloadTable: PropTypes.func.isRequired,
    iconTooltipId: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    documentType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    setIsLoading: PropTypes.func,
  };
  const [, setDocument] = useState();

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 3 * 1024 * 1024) {
      reloadTable();
      toast.error("File size exceeds the 3MB limit ");
    } else {
      setDocument(file);
      handleSubmitData(file);
    }
  };

  const handleSubmitData = async (file) => {
    setIsLoading(true);
    let docData = new FormData();
    docData.append("documentType", documentType);
    docData.append("file", file);

    try {
      await AxiosInstance.post(`${documentDetailUpload}/${entityId}`, docData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      reloadTable();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      justify="center"
      lg={1}
      sm={1}
      xl={1}
      xs={1}
      m={1}>
      <Form className="employeeForm documentDetail-icon">
        <Input
          id="file"
          name="documentPath"
          type="file"
          style={{ display: "none" }}
          onChange={handleDocumentChange}
        />
        <Tooltip title={<IntlMessages id={iconTooltipId} />}>
          <label
            htmlFor="file"
            className={disable ? "d-none" : ""}>
            <IconButton
              className="filter-button-padding"
              aria-label="upload picture"
              component="span">
              <AddIcon className="add-icon" />
            </IconButton>
          </label>
        </Tooltip>
      </Form>
    </Grid>
  );
};
export default AddDocumentDetail;
