import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Toolbar from "./Toolbar.js";
import ViewColumns from "./toolbarUtils/ViewColumns.js";
import Search from "./toolbarUtils/Search.js";
import { GenericTable } from "./GenericTable.js";
import Filters, { FilterChip } from "./toolbarUtils/Filters";
import { getPageFromStorage, getRowsPerPageFromStorage, getSortOrder, getViewColumns, setPageInStorage } from "./PreferenceHelper.js";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  root: {},
  content: {
    marginTop: 2,
  },
}));

const DataTableWrapper = ({
  key,
  cubeModelName,
  listType,
  defaultFilter,
  columns,
  toolsList,
  totalRowCount,
  setTotalRowCount,
  defaultSortOrder,
  customColumns,
  setSelectedRowData,
  selectedRows,
  setSelectedRows,
  toolbarSize,
  dynamicFilters = [],
  isDialogTable = false,
  RowsPerPage = 10,
  filterOptions = [],
  exportDataId,
  selectedRowId,
  disabled,
  day,
  month,
  year,
  ...rest
}) => {
  DataTableWrapper.propTypes = {
    key: PropTypes.string,
    cubeModelName: PropTypes.string.isRequired,
    listType: PropTypes.string,
    defaultFilter: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    toolsList: PropTypes.array.isRequired,
    totalRowCount: PropTypes.number,
    setTotalRowCount: PropTypes.func,
    defaultSortOrder: PropTypes.array.isRequired,
    customColumns: PropTypes.array.isRequired,
    setSelectedRowData: PropTypes.func.isRequired,
    selectedRows: PropTypes.array,
    setSelectedRows: PropTypes.func,
    toolbarSize: PropTypes.number,
    dynamicFilters: PropTypes.array,
    isDialogTable: PropTypes.bool,
    RowsPerPage: PropTypes.number,
    filterOptions: PropTypes.array,
    exportDataId: PropTypes.string,
    selectedRowId: PropTypes.string,
    disabled: PropTypes.bool,
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
  };

  DataTableWrapper.defaultProps = {
    dynamicFilters: [],
    isDialogTable: false,
    RowsPerPage: 10,
    filterOptions: [],
  };
  const classes = useStyles();
  const [sorting, setSorting] = useState(getSortOrder(listType, defaultSortOrder));
  const [filterList, setFilterList] = useState([defaultFilter]); //filter list
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPageFromStorage(listType, RowsPerPage));
  const [page, setPage] = useState(getPageFromStorage(listType, 0));
  const [visibleColumns, setVisibleColumns] = useState(getViewColumns(listType, columns));
  const [searchKeyword, setSearchKeyword] = useState("");
  const [clearDate, setClearDate] = useState(false);
  const [filters, setFilters] = useState(filterOptions); // filter option shown in filter menu
  const [selectedFiltersFromMenu, setSelectedFiltersFromMenu] = useState([]); // filter list when user applies filter from menu

  const toolList = [
    <Search
      key=""
      setSearchKeyword={setSearchKeyword}
      toolbarSize={toolsList.length + 4}
    />,
    <Filters
      key=""
      filters={filters}
      setFilters={setFilters}
      typeOfList={listType}
      clearDate={clearDate}
    />,
    <ViewColumns
      key=""
      columns={visibleColumns}
      setColumns={setVisibleColumns}
      typeOfList={listType}
      disabled={disabled}
    />,

    ...toolsList,
  ];
  useEffect(() => {
    if (searchKeyword) {
      setPage(0);
      setPageInStorage(listType, 0);
      let searchFilterList = visibleColumns
        .map((item) => {
          if (!item.isCustomCol && item.isSearchable) {
            return {
              dimension: item.value,
              operator: "contains",
              values: [`${searchKeyword}`],
            };
          }
        })
        .filter((x) => x);
      let fl = [defaultFilter, { or: searchFilterList }];
      setFilterList(fl);
    } else {
      setFilterList([]);
    }
  }, [searchKeyword]);

  useEffect(() => {
    let combinedDateFilter = filters.find((filt) => filt.type == "combinedDate");
    let selFilters = filters
      .map((filter) => {
        let selValues = [];
        if (filter.type == "checkbox" || filter.type == "dropdown") {
          selValues = filter.values
            .map((val) => {
              if (val?.isChecked) {
                return `${val.value}`;
              }
            })
            .filter((obj) => obj);
        }
        if (filter.type == "date" && filter.values.length == 2) {
          selValues = filter.values;
        }
        if (filter.type == "dropdown" && filter.values.length == 2) {
          selValues = filter.values;
        }
        if (selValues?.length > 0) {
          return { dimension: filter.dimension, operator: filter.operator, values: selValues };
        }
      })
      .filter((obj) => obj);
    if (combinedDateFilter && combinedDateFilter.values.length == 2) {
      selFilters = [
        ...selFilters,
        {
          and: [
            { dimension: combinedDateFilter.dimension1, operator: combinedDateFilter.operator1, values: [combinedDateFilter.values[0], combinedDateFilter.values[1]] },
            { dimension: combinedDateFilter.dimension2, operator: combinedDateFilter.operator2, values: [combinedDateFilter.values[0], combinedDateFilter.values[1]] },
          ],
        },
      ];
    }
    setSelectedFiltersFromMenu((prev) => [...selFilters]);
  }, [filters]);

  const query = {
    renewQuery: true,
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    dimensions: visibleColumns
      .map((item) => {
        if (!item.isCustomCol) {
          return item.value;
        }
      })
      .filter((x) => x != null),
    filters: [...filterList, ...dynamicFilters, ...selectedFiltersFromMenu, defaultFilter],
  };
  const countQuery = {
    renewQuery: true,
    order: {
      [`${sorting[0]}`]: sorting[1],
    },
    measures: [`${cubeModelName}.count`],
    filters: [...filterList, ...dynamicFilters, ...selectedFiltersFromMenu, defaultFilter],
  };

  return (
    <div className={classes.root}>
      <Toolbar
        key={key}
        toolList={toolList}
        setSearchKeyword={setSearchKeyword}
        toolbarSize={toolbarSize}
        style={{ position: "sticky" }}
      />
      <br />
      <FilterChip
        key={key}
        filters={filters}
        setFilters={setFilters}
        selectedFiltersFromMenu={selectedFiltersFromMenu}
        setClearDate={setClearDate}
      />
      <div className={classes.content}>
        <GenericTable
          key={key}
          sorting={sorting}
          setSorting={setSorting}
          query={query}
          countQuery={countQuery}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          columns={visibleColumns}
          listType={listType}
          cubeModelName={cubeModelName}
          customColumns={customColumns}
          setSelectedRowData={setSelectedRowData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          totalRowCount={totalRowCount}
          setTotalRowCount={setTotalRowCount}
        />
      </div>
    </div>
  );
};

export default DataTableWrapper;
