import { Checkbox, Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import PropTypes from "prop-types";
import React from "react";
import IntlMessages from "../../../../util/IntlMessages";
import { setViewColumns } from "../PreferenceHelper";

const ViewColumns = ({ columns, setColumns, typeOfList, disabled }) => {
  ViewColumns.propTypes = {
    columns: PropTypes.array.isRequired,
    setColumns: PropTypes.func.isRequired,
    typeOfList: PropTypes.string,
    disabled: PropTypes.bool,
  };
  const handleViewColChange = (item) => {
    let newList = columns.map((obj) => {
      let tempObj = { ...obj };
      if (tempObj.value == item.value) {
        tempObj.isDisplay = !item.isDisplay;
      }
      return tempObj;
    });
    setViewColumns(typeOfList, newList);
    setColumns(newList);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        item
        lg={1}
        sm={1}
        xl={1}
        xs={3}
        m={1}>
        <Tooltip title={<IntlMessages id="viewColumns.tooltip.text" />}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className="filter-button-padding"
            disabled={disabled}>
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Menu
        dense
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}>
        {columns.map(
          (item, index) =>
            item.isVisible && (
              <MenuItem
                dense
                value={item}
                key={index}
                onClick={() => handleViewColChange(item)}>
                <Checkbox
                  color="primary"
                  key={index}
                  checked={item.isDisplay}></Checkbox>
                <IntlMessages id={item.text} />
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};
export default ViewColumns;
