import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../config/AxiosConfig";
import { leaveGrantApi } from "../../redux/ApiLists";
import { getActionsObject } from "../../util/Helper";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import AddEntityWrapper from "../shared/dataTable/toolbarUtils/AddEntityWrapper";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import CompactMenuWithActions from "../shared/spinner/CompactMenuWithActions";
import ApplyLeaveAgainstLeaveGrant from "./ApplyLeaveAgainstLeaveGrant";
import ApplyLeaveGrant from "./ApplyLeaveGrant";
import { GrantStatusEnum, DOCUMENT_TYPES, GrantStatus } from "../shared/Enums";
import { toast } from "react-toastify";
import { getProfile } from "../authentication/HandleCookies";
const myParam = new URLSearchParams(location.search).get("status");

const LeaveGrantList = () => {
  const cubeModelName = "LeaveGrant";
  const [reload, reloadTable] = useState(false);
  const [, setSelectedRowData] = useState([]);
  const [rowData, setRowData] = useState();

  const [openLeaveGrantRedeemPopup, setOpenLeaveGrantRedeemPopup] = useState(false);

  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };
  const refreshTable = () =>
    setTimeout(() => {
      reloadTable((prev) => !prev);
    }, 1500);
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.fromDate`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.leaveGrantId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "cols.txt.empId",
      value: `Employee.employeeId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "leave-grant-leaveGrantType",
      value: `${cubeModelName}.leaveGrantType`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
      defaultVal: "--",
    },
    {
      text: "leave-grant-grantReason",
      value: `${cubeModelName}.grantReason`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
    },
    {
      text: "leave-grant-fromDate",
      value: `${cubeModelName}.fromDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave-grant-toDate",
      value: `${cubeModelName}.toDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave-grant-expirationDate",
      value: `${cubeModelName}.expirationDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave.grant.leaveStartDate",
      value: `${cubeModelName}.leaveStartDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave.grant.leaveEndDate",
      value: `${cubeModelName}.leaveEndDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave.grant.leaveStatus",
      value: `${cubeModelName}.leaveStatus`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "leave-grant-daysGrant",
      value: `${cubeModelName}.daysGrant`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
      defaultVal: "--",
    },
    {
      text: "leave-grant-comment",
      value: `${cubeModelName}.comment`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
    },
    {
      text: "leave-grant-status",
      value: `${cubeModelName}.grantStatus`,
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isStatusColumn: true,
      statusTypeList: GrantStatus,
      isSearchable: true,
    },
    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
      style: { position: "sticky", right: "0px", width: "0px", backgroundColor: "#fefefe" },
    },
    {
      text: "Actions",
      value: `${cubeModelName}.actions`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isCustomCol: false,
      isSorting: false,
    },
  ];
  const filterOptions = [
    {
      dimension: `${cubeModelName}.grantStatus`,
      name: "grantStatus",
      label: "Grant Status",
      values: GrantStatus?.map((ls) => {
        let updatedValue = { label: ls.status, value: ls.status };
        if (ls?.status == myParam) {
          updatedValue.isChecked = true;
        }
        return updatedValue;
      }),
      type: "checkbox",
      operator: "equals",
    },
  ];

  const API = {
    delete: `${leaveGrantApi}`,
  };

  function handleApplyLeave(option) {
    setRowData(option.rowData);
    setOpenLeaveGrantRedeemPopup(true);
  }

  async function handleActionCall(option) {
    if (option.action == GrantStatusEnum.LEAVE_APPLIED) {
      handleApplyLeave(option);
      return;
    }
    const rowData = option.rowData;
    const leaveGrantId = rowData[`${cubeModelName}.leaveGrantId`];
    const statusUpdate = option.action;
    await AxiosInstance.put(`${leaveGrantApi}/update-status/${leaveGrantId}/${statusUpdate}`)
      .then((response) => {
        reloadTable((prev) => !prev);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const actionHtml = (rowData) => {
    const options = getActionsObject(rowData, cubeModelName, handleActionCall);
    return (
      <Row>
        <Col className="actionButtons">
          <CompactMenuWithActions
            options={options}
            rowData={rowData}
            reloadTable={refreshTable}
            cubeModelName={cubeModelName}
            popupComponent={ApplyLeaveGrant}
            showEditComponent={true}
            ShowDeleteComponent={true}
            disableButton={rowData[`${cubeModelName}.grantStatus`] == GrantStatusEnum.GRANT_APPLIED ? false : true}
            ShowStatus={true}
            api={API}
            Id={rowData[`${cubeModelName}.leaveGrantId`]}
            message={"Leave Grant"}
            cubeModelId={rowData[`${cubeModelName}.leaveGrantId`]}
            showAttachments={true}
            documentType={DOCUMENT_TYPES.LEAVE_GRANT_DOC}
          />
        </Col>
      </Row>
    );
  };

  const customCols = [{ id: "action_column", component: actionHtml }];

  const customToolList = [
    <AddEntityWrapper
      key=""
      reloadTable={refreshTable}
      iconTooltipId={`leave-grant-add`}
      popupComponent={ApplyLeaveGrant}
    />,
  ];

  return (
    <>
      <TitleHeader pageTitle={"leave-grant-title"} />
      <DataTableWrapper
        key={`key_LeaveGrant_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.LeaveGrantList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
      {openLeaveGrantRedeemPopup && (
        <ApplyLeaveAgainstLeaveGrant
          reload={refreshTable}
          popCloseFunction={setOpenLeaveGrantRedeemPopup}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default LeaveGrantList;
