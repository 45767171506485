import Cookies from "js-cookie";

const clearCookies = async () => {
  Cookies.remove("_acct_kn_em", {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
  });
  Cookies.remove("profile_em", {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
  });
  Cookies.remove("company_code_em", {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
  });
};

const getAccessToken = () => {
  return Cookies.get("_acct_kn_em");
};
const getProfile = () => {
  return Cookies.get("profile_em") ? Cookies.get("profile_em") : null;
};
const getCompanyCode = () => {
  return Cookies.get("company_code_em");
};

const setCookies = (loginResponse) => {
  Cookies.set("_acct_kn_em", loginResponse.token.access_token, {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
  Cookies.set("profile_em", JSON.stringify(loginResponse.profile), {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
  Cookies.set("company_code_em", loginResponse.profile.attributes.company_code, {
    path: "/",
    domain: process.env.REACT_APP_COOKIES_MAIN_DOMAIN,
    expires: convertSecondsToDay(loginResponse.token.expires_in),
  });
};

const convertSecondsToDay = (seconds) => {
  return Math.floor(seconds / (3600 * 24));
};

export { clearCookies, getAccessToken, getCompanyCode, getProfile, setCookies };
