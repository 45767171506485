import { Grid, IconButton, InputAdornment, TextField, Tooltip, useTheme, useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import IntlMessages from "../../../../util/IntlMessages";

const Search = ({ setSearchKeyword, toolbarSize }) => {
  Search.propTypes = {
    setSearchKeyword: PropTypes.func.isRequired,
    toolbarSize: PropTypes.number,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const textInput = useRef(null);

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value.trim());
  };

  const handleClose = () => {
    setShow(false);
    setSearchKeyword("");
    textInput.current.value = "";
    setExpanded(false);
  };

  const handleSearchIconClick = () => {
    setShow(true);
    setExpanded(true);
  };

  useEffect(() => {
    if (show) {
      textInput.current.focus();
    }
  }, [show]);

  return (
    <>
      <Grid
        className="mb-3"
        container
        justifyContent="center"
        item
        lg={9 - toolbarSize}
        sm={10 - toolbarSize}
        xl={9 - toolbarSize}
        md={10 - toolbarSize}
        xs={12}
        style={{
          position: isMobile && expanded ? "absolute" : "relative",
          zIndex: 9999,
          paddingRight: "15px",
        }}>
        {show && (
          <TextField
            variant="standard"
            label={<IntlMessages id={"searchBox.label"} />}
            type="text"
            fullWidth
            inputRef={textInput}
            autoFocus={expanded}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  onClick={handleClose}>
                  <ClearIcon style={{ cursor: "pointer", marginRight: "-5px" }} />
                </InputAdornment>
              ),
              style: {
                borderBottom: "1px solid #2a52be",
              },
            }}
            sx={{
              marginTop: {
                xs: "45px",
                sm: "0px",
              },
            }}
            onChange={handleSearch}
          />
        )}
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{ display: show ? "none" : "flex" }}
        item
        lg={1}
        sm={1}
        xl={1}
        xs={1}
        m={1}>
        <Tooltip title={<IntlMessages id="searchBox.label" />}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleSearchIconClick}
            className="filter-button-padding">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
};

export default Search;
