import React, { useEffect, useState } from "react";
import { projectResourceStatus } from "../shared/Enums";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import { getProfile } from "../authentication/HandleCookies";

const ProjectList = () => {
  const cubeModelName = "ProjectResource";
  const [relaod] = useState(false);
  const [, setSelectedRowData] = useState([]);
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;

  useEffect(() => {
    document.body.classList.add("loan-component-active-more");
    return () => {
      document.body.classList.remove("loan-component-active-more");
    };
  }, []);

  const defaultFilter = {
    dimension: `${cubeModelName}.employeeId`,
    operator: "equals",
    values: [`${employeeID}`],
  };
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.projectResourceId`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.projectResourceId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "Resource Name",
      value: `Employee.fullName`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
      isSearchable: false,
      isClickable: true,
    },
    {
      text: "projectResourceList.cols.txt.role",
      value: `${cubeModelName}.role`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },

    {
      text: "projectResourceList.cols.txt.projectCode",
      value: `Project.projectCode`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "projectResourceList.cols.txt.projectName",
      value: `Project.projectName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isSearchable: true,
      style: { minWidth: "12rem", maxWidth: "12rem" },
    },
    {
      text: "projectResourceList.cols.txt.designation",
      value: `Employee.designation`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      style: { minWidth: "12rem", maxWidth: "12rem" },
    },
    {
      text: "projectResourceList.cols.txt.fromDate",
      value: `${cubeModelName}.fromDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      defaultVal: "--",
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "projectResourceList.cols.txt.toDate",
      value: `${cubeModelName}.toDate`,
      type: "date",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      defaultVal: "--",
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },

    {
      text: "projectResourceList.cols.txt.allocationPercentage",
      value: `${cubeModelName}.allocationPercentage`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: false,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },

    {
      text: "projectResourceList.cols.txt.projectResourceStatus",
      value: `${cubeModelName}.status`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: false,
      isStatusColumn: true,
      statusTypeList: projectResourceStatus,
      isSearchable: true,
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "empId",
      value: `${cubeModelName}.employeeId`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
  ];

  useEffect(() => {
    document.body.classList.add("loan-component-active-more");
    return () => {
      document.body.classList.remove("loan-component-active-more");
    };
  }, []);
  const filterOptions = [
    {
      dimension1: `${cubeModelName}.fromDate`,
      dimension2: `${cubeModelName}.toDate`,
      name: "fromToDate",
      label1: "projectResourceList.cols.txt.fromDate",
      label2: "projectResourceList.cols.txt.toDate",
      values: [],
      type: "combinedDate",
      operator1: "inDateRange",
      operator2: "inDateRange",
    },
    {
      dimension: `${cubeModelName}.status`,
      name: "ProjectStatus",
      label: "projectResourceList.cols.txt.projectResourceStatus",
      values: projectResourceStatus.map((ps) => ({ label: ps.status, value: ps.status })),
      type: "checkbox",
      operator: "equals",
    },
  ];

  const customCols = [];

  const customToolList = [];
  return (
    <>
      <TitleHeader pageTitle={"title.projects"} />
      <DataTableWrapper
        key={`key_employee_list_${relaod}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.projectResourceList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
    </>
  );
};
export default ProjectList;
