import PropTypes from "prop-types";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
const TitleHeader = ({ title, pageTitle, shouldShowArrow = true, shouldShowBackArrow = false }) => {
  TitleHeader.propTypes = {
    title: PropTypes.string,
    pageTitle: PropTypes.string,
    shouldShowArrow: PropTypes.bool,
    shouldShowBackArrow: PropTypes.bool,
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title || ""}</title>
      </Helmet>
      <div className="mb-3 PageTitleBar">
        {shouldShowArrow && (
          <h4 className="arrowColor m-1">
            <MdArrowForwardIos />
          </h4>
        )}
        {shouldShowBackArrow && (
          <h4 className="backArrowColor m-1">
            <Link to="/my-profile">
              <MdArrowBackIos />
            </Link>
          </h4>
        )}
        <h4>
          <IntlMessages id={pageTitle || "defaultHeader"} />
        </h4>
      </div>
    </HelmetProvider>
  );
};

export default TitleHeader;
