import cubejs from "@cubejs-client/core";
import WebSocketTransport from "@cubejs-client/ws-transport";
import { getAccessToken } from "../components/authentication/HandleCookies";

export async function getCubeJsApi() {
  let token = getAccessToken();
  return cubejs({
    transport: new WebSocketTransport({
      authorization: token,
      apiUrl: process.env.REACT_APP_CUBE_WS_URL,
      // apiUrl: "ws://localhost:7000",
    }),
  });
}
